import { createApi } from '@reduxjs/toolkit/query/react';
import { CreateEquipmentRequest, EquipmentResponse, EquipmentResponsePaginationResponse, Ordering } from 'types';

import { baseQuery } from './baseQuery';

export interface EquipmentsListRequestParams {
  /** @format int64 */
  companyId?: number;
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  columnName?: string;
  ordering?: Ordering;
}

export interface EquipmentsSearchRequestParams {
  tag?: string;
  description?: string;
  /** @format int32 */
  take?: number;
}

export const equipmentsApi = createApi({
  reducerPath: 'equipmentsApi',
  baseQuery: baseQuery,
  tagTypes: ['Equipments'],
  endpoints: builder => ({
    getAll: builder.query<EquipmentResponsePaginationResponse, EquipmentsListRequestParams | undefined>({
      query: params => {
        return {
          url: 'equipments/library',
          params: params,
        };
      },
      providesTags: () => ['Equipments'],
    }),
    find: builder.query<EquipmentResponse[], EquipmentsSearchRequestParams>({
      query: params => ({
        url: 'equipments',
        params: params,
      }),
      providesTags: () => ['Equipments'],
    }),
    create: builder.mutation<EquipmentResponse, CreateEquipmentRequest>({
      query: body => ({
        url: 'equipments',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Equipments'],
    }),
  }),
});
