export const routes = {
  main: '/',
  users: '/users',
  documents: '/documents',
  profile: '/profile',
  upload: '/upload',
  companies: '/companies',
  emergencyTasks: '/requests-for-repairs',
  helpdesk: '/helpdesk',
  search: '/search',
  serviceUnit: '/service-unit',
  dashboard: '/dashboard',
  websocket: '/websocket',
  notifications: '/notifications',
  scheduledWorkOrders: '/scheduled-work-orders',
  scheduledTasks: '/scheduled-tasks',
  plans: '/plans',
  logs: '/logs',
  dwgAndVendor: '/dwg-and-vendor',
  reportTasks: '/registry-of-tasks-from-reports',
  translationTask: '/translation-tasks',
} as const;

export const beRoutes = {
  scheduledWorkOrders: '/scheduled-work-orders',
  scheduledTasks: '/scheduled-tasks',
  emergencyTasks: '/emergency-tasks',
} as const;
