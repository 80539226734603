import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BoardTranslationTaskResponse,
  TranslationTaskResponse,
  TranslationTaskType,
  UpdateTranslationTaskRequest,
} from 'types';

import { baseQuery } from './baseQuery';

export const translationApi = createApi({
  reducerPath: 'translationApi',
  baseQuery: baseQuery,
  tagTypes: ['Translation Task', 'Translation Tasks'],
  endpoints: builder => ({
    getAllTranslationTasks: builder.query<BoardTranslationTaskResponse[], object>({
      query: () => ({
        url: 'translation',
      }),
      providesTags: () => ['Translation Tasks'],
    }),
    getTranslationTaskDetail: builder.query<TranslationTaskResponse, { id: number; type: TranslationTaskType }>({
      query: ({ id, type }) => ({
        url: `translation/${id}`,
        params: { type },
      }),
      providesTags: () => ['Translation Task'],
    }),
    updateTranslationTask: builder.mutation<
      TranslationTaskResponse,
      { translationTaskId: number; data: UpdateTranslationTaskRequest }
    >({
      query: ({ translationTaskId, data }) => ({
        url: `/translation/${translationTaskId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Translation Task', 'Translation Tasks'],
    }),
  }),
});
