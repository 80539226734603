import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateEmergencyTaskRequest,
  EmergencyTaskGlobalStatus,
  EmergencyTaskRegistryResponse,
  EmergencyTaskResponse,
  EmergencyTaskWebReportResponse,
  File,
  Ordering,
  SlimEmergencyTaskResponsePaginationResponse,
  UpdateEmergencyTaskRequest,
} from 'types';

import { baseQuery } from './baseQuery';

export interface EmergencyTaskRequest {
  companiesIds?: number[];
  statuses?: EmergencyTaskGlobalStatus[];
  subjectsIds?: number[];
  subjectWithChildIds?: number[];
  from?: string;
  to?: string;
  isAwaitingUserAction?: boolean;
  userIds?: number[];
  pageNumber?: number;
  pageSize?: number;
  columnName?: string;
  ordering?: Ordering;
}

export const emergencyTaskApi = createApi({
  reducerPath: 'emergencyTaskApi',
  baseQuery: baseQuery,
  tagTypes: ['Emergency Tasks', 'Emergency Task'],
  endpoints: builder => ({
    getAllEmergencyTasks: builder.query<SlimEmergencyTaskResponsePaginationResponse, EmergencyTaskRequest>({
      query: params => ({
        params,
        url: 'emergency-tasks',
      }),
      providesTags: () => ['Emergency Tasks'],
    }),
    getEmergencyTaskById: builder.query<EmergencyTaskResponse, number>({
      query: id => ({
        url: `emergency-tasks/${id}`,
      }),
      providesTags: () => ['Emergency Task'],
    }),
    getEmergencyTaskRegistry: builder.query<EmergencyTaskRegistryResponse[], EmergencyTaskRequest>({
      query: params => ({
        params,
        url: 'emergency-tasks/registry',
      }),
      providesTags: () => ['Emergency Task'],
    }),
    getEmergencyTaskReportById: builder.query<any, number>({
      query: id => ({
        url: `emergency-tasks/${id}/report`,
      }),
      providesTags: () => ['Emergency Task'],
    }),
    createEmergencyTask: builder.mutation<EmergencyTaskResponse, CreateEmergencyTaskRequest>({
      query: emergencyTask => ({
        url: '/emergency-tasks',
        method: 'POST',
        body: emergencyTask,
      }),
      invalidatesTags: ['Emergency Tasks'],
    }),
    editEmergencyTask: builder.mutation<EmergencyTaskResponse, { id: number; form: UpdateEmergencyTaskRequest }>({
      query: ({ id, form }) => ({
        url: `/emergency-tasks/${id}`,
        method: 'PUT',
        body: form,
      }),
      invalidatesTags: ['Emergency Tasks', 'Emergency Task'],
    }),
    uploadEmergencyTaskFile: builder.mutation<File, FormData>({
      query: emergencyTask => ({
        url: '/emergency-tasks/files',
        method: 'POST',
        body: emergencyTask,
      }),
      invalidatesTags: ['Emergency Task'],
    }),
    getArchive: builder.query<File, EmergencyTaskRequest>({
      query: params => {
        return {
          url: 'EmergencyTasks/archive',
          params: params,
        };
      },
      providesTags: () => ['Emergency Task'],
    }),
    deleteEmergencyTaskById: builder.mutation<unknown, number>({
      query: emergencyTaskId => ({
        url: `emergency-tasks/${emergencyTaskId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Emergency Tasks'],
    }),
    deleteEmergencyTaskFileById: builder.mutation<unknown, number>({
      query: fileId => ({
        url: `emergency-tasks/files/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Emergency Task'],
    }),
    getWebReport: builder.query<EmergencyTaskWebReportResponse, number>({
      query: emergencyTaskId => ({
        url: `emergency-tasks/${emergencyTaskId}/report/web`,
      }),

      providesTags: () => ['Emergency Task'],
    }),
  }),
});
