import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, message, Select } from 'antd/lib';
import { useForm } from 'antd/lib/form/Form';
import clsx from 'clsx';
import { companyAPI } from 'services/CompanyService';
import { regulatoryDocumentsApi } from 'services/RegulatoryDocumentsService';
import { CompanyRoleType } from 'types';
import { getOptions } from 'utils/getOptions';

const inputCls = clsx(
  '[&>.ant-row>.ant-form-item-label>label]:text-primary',
  '[&>.ant-row>.ant-form-item-label>label]:font-semibold',
  '[&>.ant-row>.ant-form-item-label>label]:text-[20px]',
  '[&>.ant-row>.ant-form-item-label>label]:leading-[16px]',
);

export default function AddRegulatoryDocument({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation('main-panel');
  const [form] = useForm();
  const [create, { isLoading, isSuccess }] = regulatoryDocumentsApi.useCreateMutation();
  const { data: companies = [] } = companyAPI.useGetAllQuery();

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    message.success('Document added');
    onClose();
  }, [isSuccess, onClose]);

  const onFinish = useCallback(
    ({ code, name, companyId }: { code?: string; name?: string; companyId?: number }) => {
      create({
        code,
        name,
        companyId,
      });
    },
    [create],
  );

  // Компания-заказчик не может быть владельцем документа
  const options = getOptions(companies.filter(({ role }) => role !== CompanyRoleType.Customer));

  return (
    <div className="flex h-full max-h-full flex-col pb-5">
      <div className="mb-10 mt-16 flex items-center gap-2">
        <h3 className="typography-h3 w-full text-center font-bold text-primary">{t('Add regulatory document')}</h3>
      </div>
      <Form
        name="login_form"
        onFinish={onFinish}
        autoComplete="off"
        className="[&>.ant-form-item]:!mb-10"
        layout="vertical"
        disabled={isLoading}
        form={form}
      >
        <Form.Item className={inputCls} rules={[{ required: true }]} name="code" label={t('Code')}>
          <Input />
        </Form.Item>
        <Form.Item className={inputCls} rules={[{ required: true }]} name="name" label={t('Name')}>
          <Input />
        </Form.Item>
        <Form.Item className={inputCls} rules={[{ required: true }]} name="companyId" label={t('Company')}>
          <Select placeholder={t('Select company')} options={options} />
        </Form.Item>
        <Button
          loading={isLoading}
          htmlType="submit"
          className="m-auto flex min-w-[96px] justify-center"
          size="large"
          type="primary"
        >
          {t('Save')}
        </Button>
      </Form>
    </div>
  );
}
