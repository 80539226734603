import { EmergencyTaskUserResponse, ScheduledWorkResponse, UserResponse } from 'types';
import { Option } from 'types/global';

export const getOptions = <T>(
  arr: Array<T & { id?: number | null; name?: string | null }>,
): Array<{ value: number; label: string }> =>
  (arr.filter(({ name, id }) => Boolean(name && id)) as Array<T & { id: number; name: string }>).map(
    ({ id, name }) => ({ value: id, label: name }),
  );

export const getNameOptions = <T>(
  arr: Array<T & { id?: number | null; name?: string | null }>,
): Array<{ value: string; label: string }> =>
  (arr.filter(({ name, id }) => Boolean(name && id)) as Array<T & { id: number; name: string }>).map(({ name }) => ({
    value: name,
    label: name,
  }));

export const getUserOptions = (list: UserResponse[] | EmergencyTaskUserResponse[]): Option[] =>
  list
    .filter(user => ('activityStatus' in user ? user.activityStatus : true))
    .map(person => ({
      value: person.id,
      label: `${person.surname} ${person.name}`,
    }));

export const getScheduledWorkOptions = (arr: ScheduledWorkResponse[]): Array<{ value: number; label: string }> =>
  arr.map(({ id, code }) => ({ value: id, label: String(code) }));
