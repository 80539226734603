/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AttachedDocument {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  fileName: string;
}

export interface BoardScheduledTaskResponse {
  status: ScheduledTaskStatus;
  executeStatus: ScheduledTaskExecuteStatus;
}

export interface BoardScheduledWorkOrderResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  scheduledWorkCode: string;
  subjectFullName?: string | null;
  actualDate?: string | null;
  status: ScheduledWorkOrderStatus;
  workPeriod: ScheduledWorkPeriodWebModel;
  globalStatus: ScheduledWorkOrderGlobalStatus;
  scheduledTasks: BoardScheduledTaskResponse[];
  performer?: BoardUserResponse;
  responsible?: BoardUserResponse;
  customer?: BoardUserResponse;
  createdFromUnscheduledTask?: boolean;
}

export interface BoardScheduledWorkOrderResponsePaginationResponse {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  data: BoardScheduledWorkOrderResponse[];
}

export interface BoardTranslationTaskResponse {
  /** @format int64 */
  id: number;
  type: TranslationTaskType;
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  subjectFullName: string;
  status: TranslationTaskStatus;
}

export interface BoardUserResponse {
  /** @minLength 1 */
  shortName: string;
  /** @minLength 1 */
  companyName: string;
}

export interface ChangeUserPasswordRequest {
  /** @minLength 8 */
  password1: string;
  /** @minLength 8 */
  password2: string;
}

export interface CommonInfoResponse {
  sections?: SectionCommonInfoResponse[] | null;
}

export interface CompanyResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  code: string;
  /** @format int32 */
  rgbColor: number;
  role: CompanyRoleType;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  phone: string;
  /** @minLength 1 */
  address: string;
  description?: string | null;
}

export enum CompanyRoleType {
  Customer = 'Customer',
  GeneralContractor = 'GeneralContractor',
  Subcontractor = 'Subcontractor',
}

export interface CompanyRoleTypeEnumResponse {
  value: CompanyRoleType;
  /** @minLength 1 */
  label: string;
}

export interface CompanyThatHaveTasksResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
}

export interface ContractorsInfoResponse {
  /** @format int64 */
  companyId?: number;
  companyName?: string | null;
  /** @format int32 */
  closedTaskCount?: number;
  /** @format int32 */
  inWorkTaskCount?: number;
  /** @format int32 */
  expiredTaskCount?: number;
}

export interface ContractorsStatisticResponse {
  contractors?: ContractorsInfoResponse[] | null;
}

/** Company creation model. */
export interface CreateCompanyRequest {
  /**
   * Company name.
   * @minLength 3
   * @maxLength 30
   */
  name: string;
  /**
   * RGB color.
   * @format int32
   */
  rgbColor: number;
  /**
   * Company code.
   * @minLength 2
   * @maxLength 7
   */
  code: string;
  role: CompanyRoleType;
  /**
   * Email.
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Phone.
   * @format tel
   * @minLength 1
   */
  phone: string;
  /**
   * Address.
   * @minLength 1
   * @maxLength 100
   */
  address: string;
  /**
   * Description.
   * @maxLength 100
   */
  description?: string | null;
}

export interface CreateEmergencyTaskRequest {
  highPriority: boolean;
  /** @format int64 */
  subjectId: number;
  repairType: EmergencyTaskRepairType;
  /**
   * @minLength 5
   * @maxLength 250
   */
  title: string;
  /**
   * @minLength 10
   * @maxLength 100000
   */
  description: string;
  /** @maxLength 1000 */
  comment?: string | null;
  filesIds?: number[] | null;
}

export interface CreateEquipmentRequest {
  tag?: string | null;
  description?: string | null;
  /** @format int64 */
  companyId?: number;
}

export interface CreateNodeRequest {
  nodeName?: string | null;
  /** @format int64 */
  parentNodeId?: number | null;
  canStoreFiles?: boolean | null;
  manualFolderManagement?: boolean | null;
}

export interface CreateNodeResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
  path?: string | null;
  nodeTreeType?: string | null;
  /** @format int64 */
  parentId?: number | null;
  type?: string | null;
  canStoreFiles?: boolean;
  manualFolderManagement?: boolean;
}

export interface CreatePositionRequest {
  /**
   * @minLength 3
   * @maxLength 20
   */
  name: string;
}

export interface CreateRegulatoryDocumentRequest {
  code?: string | null;
  name?: string | null;
  /** @format int64 */
  companyId?: number;
}

export interface CreateServiceProductRequest {
  serviceProductName?: string | null;
}

export interface CreateServiceUnitRequest {
  sectionName?: string | null;
  serviceUnitDisciplineName?: string | null;
  objectName?: string | null;
  /** @format int64 */
  serviceProductId?: number;
  /** @format int64 */
  equipmentGroupId?: number | null;
  /** @format int64 */
  manufacturerId?: number | null;
  serialNumber?: string | null;
  companiesIds?: number[] | null;
  tagsIds?: number[] | null;
  description?: string | null;
}

export interface CreateStatisticInfoRequest {
  /**
   * @format date
   * @example "2023-12-31"
   */
  startDate: string;
  /**
   * @format date
   * @example "2023-12-31"
   */
  endDate: string;
}

export interface CreateUserRequest {
  /**
   * @minLength 3
   * @maxLength 20
   */
  name: string;
  /**
   * @minLength 3
   * @maxLength 20
   */
  surname: string;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * @format tel
   * @minLength 1
   */
  phone: string;
  activityStatus?: boolean | null;
  canBeResponsible?: boolean | null;
  /** @minLength 8 */
  password1: string;
  /** @minLength 8 */
  password2: string;
  role: UserRoleType;
  /** @format int64 */
  companyId: number;
  /** @format int64 */
  positionId: number;
  disciplines?: DisciplineType[] | null;
}

export interface DisciplineCommonInfoResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
  path?: string | null;
  objects?: ObjectCommonInfoResponse[] | null;
}

export interface DisciplineResponse {
  code?: string | null;
  name?: string | null;
  title?: string | null;
}

export enum DisciplineType {
  Arc = 'Arc',
  Civ = 'Civ',
  Cln = 'Cln',
  Com = 'Com',
  Con = 'Con',
  Cpa = 'Cpa',
  Csc = 'Csc',
  Eia = 'Eia',
  Eng = 'Eng',
  Ele = 'Ele',
  Fgs = 'Fgs',
  Fin = 'Fin',
  Hse = 'Hse',
  Hva = 'Hva',
  Hyd = 'Hyd',
  Ins = 'Ins',
  Mec = 'Mec',
  Onp = 'Onp',
  Ope = 'Ope',
  Pco = 'Pco',
  Pip = 'Pip',
  Prc = 'Prc',
  Prd = 'Prd',
  Prm = 'Prm',
  Pro = 'Pro',
  Qac = 'Qac',
  Srt = 'Srt',
  Str = 'Str',
  Stc = 'Stc',
  Tel = 'Tel',
  Tin = 'Tin',
}

export enum EmergencyTaskAction {
  CreateFromUnscheduledTask = 'CreateFromUnscheduledTask',
  GoToOpen = 'GoToOpen',
  AcceptAsResponsible = 'AcceptAsResponsible',
  ReturnToResponsible = 'ReturnToResponsible',
  TakeToWork = 'TakeToWork',
  RejectTask = 'RejectTask',
  AssignPerformer = 'AssignPerformer',
  FormABrigade = 'FormABrigade',
  GoToChangePerformer = 'GoToChangePerformer',
  ReturnToInWork = 'ReturnToInWork',
  GoToAddMaterials = 'GoToAddMaterials',
  RejectAddingMaterials = 'RejectAddingMaterials',
  AssignPurchaseManager = 'AssignPurchaseManager',
  SetPlannedReceiptDateForPurchasedMaterials = 'SetPlannedReceiptDateForPurchasedMaterials',
  GoToUseSpareParts = 'GoToUseSpareParts',
  SendReportForConfirmationToResponsible = 'SendReportForConfirmationToResponsible',
  SendReportForCorrectionToPerformer = 'SendReportForCorrectionToPerformer',
  SendCorrectedReportToResponsible = 'SendCorrectedReportToResponsible',
  MakeOffer = 'MakeOffer',
  RejectOffer = 'RejectOffer',
  AcceptOffer = 'AcceptOffer',
  ReassignPerformer = 'ReassignPerformer',
  SendReportForConfirmationToAuthor = 'SendReportForConfirmationToAuthor',
  SendToTranslate = 'SendToTranslate',
  AcceptToTranslate = 'AcceptToTranslate',
  SendTranslation = 'SendTranslation',
  SendReportForCorrectionToResponsible = 'SendReportForCorrectionToResponsible',
  SendCorrectedReportToAuthor = 'SendCorrectedReportToAuthor',
  RejectOfferAndClose = 'RejectOfferAndClose',
  GoToClose = 'GoToClose',
}

export interface EmergencyTaskActionEnumResponse {
  value: EmergencyTaskAction;
  /** @minLength 1 */
  label: string;
}

export interface EmergencyTaskCreationResponse {
  /** @format int64 */
  id: number;
  highPriority: boolean;
  /** @format date-time */
  created: string;
  /** @format date-time */
  closingTime?: string | null;
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  description: string;
  /** @format int64 */
  subjectId?: number;
  globalStatus: EmergencyTaskGlobalStatus;
  status: EmergencyTaskStatus;
  author: EmergencyTaskUserResponse;
  permittedTriggers: EmergencyTaskAction[];
}

export interface EmergencyTaskEventResponse {
  /** @format int64 */
  id: number;
  actorFullName?: string | null;
  /** @format date-time */
  created: string;
  statusFrom: EmergencyTaskStatus;
  statusTo: EmergencyTaskStatus;
  action: EmergencyTaskAction;
  /** @minLength 1 */
  text: string;
  /** @minLength 1 */
  comment: string;
  files?: EmergencyTaskFileResponse[] | null;
  /**
   * @format date
   * @example "2023-12-31"
   */
  date?: string | null;
}

export interface EmergencyTaskFileResponse {
  /** @format int64 */
  id: number;
  type: FileType;
  /** @minLength 1 */
  name: string;
  extension: FileExtension;
  /** @format int64 */
  size: number;
}

export enum EmergencyTaskGlobalStatus {
  Undefined = 'Undefined',
  Pending = 'Pending',
  InWork = 'InWork',
  WaitingForAccept = 'WaitingForAccept',
  Closed = 'Closed',
}

export interface EmergencyTaskRegistryResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  created: string;
  /** @minLength 1 */
  projectPhase: string;
  /** @minLength 1 */
  subject: string;
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  authorInformation: string;
  /** @minLength 1 */
  status: string;
  responsibleInformation?: string | null;
  performerInformation?: string | null;
  plannedDate?: string | null;
  actualDate?: string | null;
  closedDate?: string | null;
  closedByAuthorDate?: string | null;
  repairCategory?: string | null;
  agreedRepairCategory?: string | null;
  conclusion?: string | null;
  additionalInfo?: string | null;
  involvedPersonnel?: string | null;
  techniqueResources?: string | null;
  approvalStatus?: string | null;
}

export enum EmergencyTaskRepairType {
  Operational = 'Operational',
  EmergencyMediumComplexity = 'EmergencyMediumComplexity',
  ComplexEmergency = 'ComplexEmergency',
}

export interface EmergencyTaskResponse {
  /** @format int64 */
  id: number;
  highPriority: boolean;
  /** @format date-time */
  created: string;
  /** @format date-time */
  closingTime?: string | null;
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  description: string;
  /** @format int64 */
  subjectId?: number;
  globalStatus: EmergencyTaskGlobalStatus;
  status: EmergencyTaskStatus;
  author: EmergencyTaskUserResponse;
  permittedTriggers: EmergencyTaskAction[];
  /** @format date-time */
  updated?: string | null;
  scheduledDepartureDate?: string | null;
  rejectComment?: string | null;
  repairType: EmergencyTaskRepairType;
  actionResponsibleUserIds?: number[] | null;
  responsible?: EmergencyTaskUserResponse;
  performer?: EmergencyTaskUserResponse;
  purchasingManager?: EmergencyTaskUserResponse;
  events: EmergencyTaskEventResponse[];
  /** @format int64 */
  parentScheduledWorkOrderId?: number | null;
  parentScheduledWorkOrderReportName?: string | null;
}

export interface EmergencyTaskStatisticResponse {
  /** @format int64 */
  numberCreatedPerPeriod: number;
  /** @format int64 */
  numberClosedPerPeriod: number;
  /** @format int64 */
  numberCreatedPerContractPeriod: number;
  /** @format int64 */
  numberClosedPerContractPeriod: number;
  /** @format int64 */
  numberClosedInAnotherPeriodWithOfferCs: number;
  /** @format int64 */
  numberClosedInAnotherPeriodWithOfferLp: number;
  /** @format int64 */
  numberClosedInAnotherPeriodWithoutOfferCs: number;
  /** @format int64 */
  numberClosedInAnotherPeriodWithoutOfferLp: number;
  /** @format int32 */
  totalLp: number;
  /** @format int32 */
  totalLpPerContractPeriod: number;
  /** @format int32 */
  totalCs: number;
  /** @format int32 */
  totalCsPerContractPeriod: number;
  /** @format int32 */
  pendingLp: number;
  /** @format int32 */
  pendingCs: number;
  /** @format int32 */
  inWorkLp: number;
  /** @format int32 */
  inWorkCs: number;
  /** @format int32 */
  awaitingForAcceptLp: number;
  /** @format int32 */
  awaitingForAcceptCs: number;
  /** @format int32 */
  closedWithOfferLp: number;
  /** @format int32 */
  closedWithOfferCs: number;
  /** @format int32 */
  closedWithoutOfferLp: number;
  /** @format int32 */
  closedWithoutOfferCs: number;
  /** @format int32 */
  rejectedLp: number;
  /** @format int32 */
  rejectedCs: number;
}

export enum EmergencyTaskStatus {
  CreatedFromUnscheduledTask = 'CreatedFromUnscheduledTask',
  Created = 'Created',
  Open = 'Open',
  Responsible = 'Responsible',
  TaskRejected = 'TaskRejected',
  InWork = 'InWork',
  Performer = 'Performer',
  ChangePerformer = 'ChangePerformer',
  BrigadeFormed = 'BrigadeFormed',
  PurchasingManager = 'PurchasingManager',
  DateOfDelivery = 'DateOfDelivery',
  SpareParts = 'SpareParts',
  Materials = 'Materials',
  CorrectionOfReportByPerformer = 'CorrectionOfReportByPerformer',
  ReportAwaitingConfirmationFromResponsible = 'ReportAwaitingConfirmationFromResponsible',
  AwaitingAcceptanceCorrectedReportFromPerformer = 'AwaitingAcceptanceCorrectedReportFromPerformer',
  CorrectionOfReportByResponsible = 'CorrectionOfReportByResponsible',
  Offer = 'Offer',
  AwaitingAssignTranslator = 'AwaitingAssignTranslator',
  Translate = 'Translate',
  AcceptedOffer = 'AcceptedOffer',
  ReportAwaitingConfirmationFromAuthor = 'ReportAwaitingConfirmationFromAuthor',
  Closed = 'Closed',
}

export interface EmergencyTaskStatusEnumResponse {
  value: EmergencyTaskStatus;
  /** @minLength 1 */
  label: string;
}

export interface EmergencyTaskUserResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  surname: string;
  /** @minLength 1 */
  companyName: string;
}

export interface EmergencyTaskUserWebReportResponse {
  /** @minLength 1 */
  shortName: string;
  /** @minLength 1 */
  position: string;
  /** @minLength 1 */
  companyCode: string;
}

export interface EmergencyTaskWebAttachedDocumentResponse {
  /** @format int64 */
  id?: number | null;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  fileName: string;
}

export interface EmergencyTaskWebRegulatoryDocumentResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  code: string;
}

export interface EmergencyTaskWebReportInfoResponse {
  listOfUsedEquipment?: EmergencyTaskWebUsedEquipmentResponse[] | null;
  listOfUsedMaterials?: EmergencyTaskWebUsedMaterialResponse[] | null;
  listOfRegulatoryDocuments?: EmergencyTaskWebRegulatoryDocumentResponse[] | null;
  listOfAttachedDocuments?: EmergencyTaskWebAttachedDocumentResponse[] | null;
  listOfWorks?: EmergencyTaskWebWorkResponse[] | null;
  /** @minLength 1 */
  conclusion: string;
}

export interface EmergencyTaskWebReportResponse {
  /** @minLength 1 */
  emergencyTaskId: string;
  /** @minLength 1 */
  creationDate: string;
  /** @minLength 1 */
  sectionName: string;
  /** @minLength 1 */
  subjectName: string;
  /** @minLength 1 */
  subjectFullName: string;
  /** @minLength 1 */
  reportFullName: string;
  /** @minLength 1 */
  revision: string;
  repairTypeInfo: RepairTypeInfoWebReportResponse;
  /** @minLength 1 */
  emergencyTaskDescription: string;
  /** @minLength 1 */
  startWorkDate: string;
  /** @minLength 1 */
  endWorkDate: string;
  performer: EmergencyTaskUserWebReportResponse;
  responsible: EmergencyTaskUserWebReportResponse;
  employee: EmergencyTaskUserWebReportResponse;
  employees?: EmergencyTaskUserWebReportResponse[] | null;
  /** @minLength 1 */
  executorCompanyRole: string;
  reportInfo?: EmergencyTaskWebReportInfoResponse;
}

export interface EmergencyTaskWebUsedEquipmentResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  tag: string;
  /** @minLength 1 */
  description: string;
}

export interface EmergencyTaskWebUsedMaterialResponse {
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  amount: number;
  measuringUnit: MeasuringUnit;
}

export interface EmergencyTaskWebWorkResponse {
  /** @minLength 1 */
  objectName: string;
}

export interface EquipmentResponse {
  /** @format int64 */
  id?: number;
  tag?: string | null;
  description?: string | null;
  /** @format int64 */
  companyId?: number;
}

export interface EquipmentResponsePaginationResponse {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  data: EquipmentResponse[];
}

export interface EquipmentUsed {
  /** @format int64 */
  equipmentId: number;
}

export type File = object;

export enum FileExtension {
  Undefined = 'Undefined',
  Pdf = 'Pdf',
  Csv = 'Csv',
  Xls = 'Xls',
  Xlsx = 'Xlsx',
  Doc = 'Doc',
  Docx = 'Docx',
  Png = 'Png',
  Jpg = 'Jpg',
  Jpeg = 'Jpeg',
}

export interface FileExtensionResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
  description?: string | null;
}

export interface FileLogResponse {
  /** @format int64 */
  id?: number;
  type?: FileType;
  name?: string | null;
  /** @format date-time */
  created?: string;
  createdByUserShortName?: string | null;
  size?: string | null;
  isDeleted?: boolean;
  deletedByUserShortName?: string | null;
  /** @format date-time */
  deleted?: string | null;
}

export interface FileLogResponsePaginationResponse {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  data: FileLogResponse[];
}

export interface FileResponse {
  /** @format int64 */
  id?: number;
  type?: FileType;
  name?: string | null;
  /** @format int64 */
  size?: number;
  extension?: FileExtension;
  user?: SlimUserResponse;
}

export enum FileType {
  Node = 'Node',
  EmergencyTask = 'EmergencyTask',
  UnscheduledTask = 'UnscheduledTask',
  ScheduledTask = 'ScheduledTask',
  ScheduledWorkOrder = 'ScheduledWorkOrder',
  ProfilePhoto = 'ProfilePhoto',
  Plan = 'Plan',
  ServiceUnit = 'ServiceUnit',
}

export enum FileTypeWebModel {
  RequestsForRepair = 'RequestsForRepair',
  RegistryOfTasksFromReport = 'RegistryOfTasksFromReport',
  PlannedTask = 'PlannedTask',
  ProfilePhoto = 'ProfilePhoto',
  Plan = 'Plan',
  VendorAndProjectDocuments = 'VendorAndProjectDocuments',
}

/** IDC problem detail */
export interface IdcProblemDetail {
  /** Detail */
  detail?: string | null;
  /**
   * Status
   * @format int32
   */
  status?: number | null;
  /** Title */
  title?: string | null;
  /** Type */
  type?: string | null;
}

export interface LoadByTaskCompanyStatisticResponse {
  /** @format int64 */
  companyId: number;
  /** @minLength 1 */
  companyName: string;
  /** @format int32 */
  companyRgbColor: number;
  /** @format date-time */
  date: string;
  /** @format int32 */
  count: number;
  countType: StatisticCountType;
}

export interface LoadByTasksStatisticResponse {
  companyStatistics?: LoadByTaskCompanyStatisticResponse[] | null;
}

/** Authorization model */
export interface LoginRequest {
  /**
   * Login
   * @minLength 1
   */
  login: string;
  /**
   * Password
   * @minLength 1
   */
  password: string;
}

/** Authorization response */
export interface LoginResponse {
  /** Access token */
  accessToken?: string | null;
  /** Refresh tocken */
  refreshToken?: string | null;
}

export enum MeasuringUnit {
  One = 'One',
  Box = 'Box',
  Kit = 'Kit',
  Kilogram = 'Kilogram',
  Ton = 'Ton',
  Meter = 'Meter',
  SquareMeter = 'SquareMeter',
  CubicMeter = 'CubicMeter',
}

export interface MeasuringUnitEnumResponse {
  value: MeasuringUnit;
  /** @minLength 1 */
  label: string;
}

export interface NodeResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
  path?: string | null;
  nodeTreeType?: string | null;
  canStoreFiles?: boolean;
  manualFolderManagement?: boolean;
  /** @format int64 */
  parentNodeId?: number | null;
  children?: TreeNodeResponse[] | null;
  nodeFiles?: FileResponse[] | null;
  hasServiceUnits?: boolean;
}

export interface NotificationAdditionalInfoResponse {
  additionalInfoType: NotificationAdditionalInfoType;
  /** @minLength 1 */
  value: string;
}

export enum NotificationAdditionalInfoType {
  Undefined = 'Undefined',
  Title = 'Title',
}

export interface NotificationLinkResponse {
  linkType: NotificationLinkType;
  /** @format int64 */
  id: number;
}

export enum NotificationLinkType {
  Undefined = 'Undefined',
  EmergencyTask = 'EmergencyTask',
  ScheduledTask = 'ScheduledTask',
  ScheduledWorkOrder = 'ScheduledWorkOrder',
  PlanFile = 'PlanFile',
}

export interface NotificationResponse {
  /** @format int64 */
  id: number;
  active: boolean;
  viewed: boolean;
  type?: NotificationType;
  /** @format date-time */
  created: string;
  /** @minLength 1 */
  message: string;
  links?: NotificationLinkResponse[] | null;
  additionalInfos?: NotificationAdditionalInfoResponse[] | null;
}

export interface NotificationResponsePaginationResponse {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  data: NotificationResponse[];
}

export enum NotificationType {
  Undefined = 'Undefined',
  UploadMonthlyPlanFile = 'UploadMonthlyPlanFile',
  EmergencyTask = 'EmergencyTask',
  ScheduledTask = 'ScheduledTask',
  ScheduledTaskExpired = 'ScheduledTaskExpired',
  ScheduledWorkOrder = 'ScheduledWorkOrder',
  ScheduledWorkOrderExpired = 'ScheduledWorkOrderExpired',
  Request = 'Request',
}

export interface ObjectCommonInfoResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
  path?: string | null;
}

export enum Ordering {
  Asc = 'Asc',
  Desc = 'Desc',
}

export interface PlanFileResponse {
  /** @format int64 */
  id: number;
  type: FileType;
  /** @minLength 1 */
  name: string;
  /** @format int64 */
  size: number;
  extension: FileExtension;
  planPeriod: PlanPeriod;
}

export enum PlanPeriod {
  Undefined = 'Undefined',
  Y = 'Y',
  M = 'M',
  W = 'W',
}

export interface PlannedPeriodResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  projectPhase: string;
  /** @minLength 1 */
  date: string;
  /** @format int32 */
  plannedReportCount: number;
}

export interface PositionResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
}

export interface PreviewScheduleTaskChecklistRequest {
  conclusion?: string | null;
  scheduledWorkDetailStates?: ScheduledWorkDetailStatesRequest[] | null;
}

export interface ProfilePhotoResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  displayName: string;
  /** @format byte */
  blobData: string;
  extension: FileExtension;
}

/** Refresh token model */
export interface RefreshTokenRequest {
  /** Access token */
  accessToken?: string | null;
  /**
   * Refresh token
   * @minLength 1
   */
  refreshToken: string;
}

export interface RegulatoryDocument {
  /** @format int64 */
  regulatoryDocumentId: number;
}

export interface RegulatoryDocumentResponse {
  /** @format int64 */
  id?: number;
  code?: string | null;
  name?: string | null;
  /** @format int64 */
  companyId?: number;
}

export interface RegulatoryDocumentResponsePaginationResponse {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  data: RegulatoryDocumentResponse[];
}

export interface RepairTypeInfoWebReportResponse {
  /** @minLength 1 */
  repairTypeItemNumber: string;
  /** @minLength 1 */
  description: string;
}

export interface ScheduleTaskUpdateRequest {
  action?: ScheduledTaskAction;
  filesIds?: number[] | null;
  conclusion?: string | null;
  comment?: string | null;
  scheduledWorkDetailStates?: ScheduledWorkDetailStatesRequest[] | null;
}

export interface ScheduleWorkOrderUpdateRequest {
  action: ScheduledWorkOrderAction;
  comment?: string | null;
  filesIds?: number[] | null;
  listOfUsedEquipment?: ScheduledWorkOrderEquipmentUsedRequest[] | null;
  listOfUsedMaterials?: ScheduledWorkOrderUsedMaterialRequest[] | null;
  listOfRegulatoryDocuments?: ScheduledWorkOrderRegulatoryDocumentRequest[] | null;
  listOfAttachedDocuments?: ScheduledWorkOrderAttachedDocumentRequest[] | null;
  listOfTechnique?: ScheduledWorkOrderTechniqueResourceRequest[] | null;
  listOfInvolvedPersonnel?: ScheduledWorkOrderInvolvedPersonnelRequest[] | null;
  listOfUnscheduledTask?: ScheduledWorkOrderUnscheduledTaskRequest[] | null;
  conclusion?: string | null;
}

export enum ScheduledTaskAction {
  CreateFromMonthlyPlan = 'CreateFromMonthlyPlan',
  CreateFromWeeklyPlan = 'CreateFromWeeklyPlan',
  CreateFromUnscheduledTask = 'CreateFromUnscheduledTask',
  ActualizeFromWeeklyPlan = 'ActualizeFromWeeklyPlan',
  SendChecklistForConfirmationToCustomer = 'SendChecklistForConfirmationToCustomer',
  SendChecklistForConfirmationToResponsible = 'SendChecklistForConfirmationToResponsible',
  AcceptAndSendChecklistForConfirmationToCustomer = 'AcceptAndSendChecklistForConfirmationToCustomer',
  AcceptChecklist = 'AcceptChecklist',
  RejectChecklist = 'RejectChecklist',
}

export interface ScheduledTaskActionEnumResponse {
  value: ScheduledTaskAction;
  /** @minLength 1 */
  label: string;
}

export interface ScheduledTaskChecklistInfoResponse {
  conclusion?: string | null;
}

export interface ScheduledTaskCommentResponse {
  comment?: string | null;
  action?: ScheduledTaskAction;
  /** @format date-time */
  created?: string;
}

export enum ScheduledTaskExecuteStatus {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Expired = 'Expired',
  SuccessfullyClosed = 'SuccessfullyClosed',
}

export enum ScheduledTaskFileType {
  Common = 'Common',
  Checklist = 'Checklist',
}

export interface ScheduledTaskResponse {
  /** @format int64 */
  id: number;
  /** @format int64 */
  scheduledWorkOrderId: number;
  description?: string | null;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated?: string;
  plannedDate?: string | null;
  actualDate?: string | null;
  /** @format date-time */
  dateProvidingChecklist?: string | null;
  subject?: SubjectResponse;
  /** @minLength 1 */
  scheduledWorkCode: string;
  status: ScheduledTaskStatus;
  executeStatus: ScheduledTaskExecuteStatus;
  commentsOnRejection?: string[] | null;
  permittedTriggers?: ScheduledTaskAction[] | null;
  /** @format int64 */
  actionResponsibleUserId?: number | null;
  customer?: ScheduledTaskUserResponse;
  responsible?: ScheduledTaskUserResponse;
  performer?: ScheduledTaskUserResponse;
  scheduledWorkEquipmentGroups?: ScheduledWorkEquipmentGroupResponse[] | null;
  comments?: ScheduledTaskCommentResponse[] | null;
  checklistInfo?: ScheduledTaskChecklistInfoResponse;
  tagOrLabel?: string | null;
  subsystemName?: string | null;
}

export enum ScheduledTaskStatus {
  Created = 'Created',
  CreatedFromWeeklyPlan = 'CreatedFromWeeklyPlan',
  MonthlyPlan = 'MonthlyPlan',
  WeeklyPlan = 'WeeklyPlan',
  AwaitingChecklistAcceptanceFromResponsible = 'AwaitingChecklistAcceptanceFromResponsible',
  AwaitingChecklistAcceptanceFromCustomer = 'AwaitingChecklistAcceptanceFromCustomer',
  ChecklistAccepted = 'ChecklistAccepted',
}

export interface ScheduledTaskStatusEnumResponse {
  value: ScheduledTaskStatus;
  /** @minLength 1 */
  label: string;
}

export interface ScheduledTaskUserResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  surname: string;
  /** @minLength 1 */
  companyName: string;
}

export interface ScheduledWorkDetailStateResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  description: string;
  completed: boolean;
}

export interface ScheduledWorkDetailStatesRequest {
  /** @format int64 */
  id?: number;
  completed?: boolean;
}

export interface ScheduledWorkEquipmentGroupResponse {
  /** @minLength 1 */
  name: string;
  scheduledWorkDetailsState: ScheduledWorkDetailStateResponse[];
}

export enum ScheduledWorkOrderAction {
  CreateFromMonthlyPlan = 'CreateFromMonthlyPlan',
  CreateFromWeeklyPlan = 'CreateFromWeeklyPlan',
  CreateFromUnscheduledTask = 'CreateFromUnscheduledTask',
  ActualizeFromWeeklyPlan = 'ActualizeFromWeeklyPlan',
  AllChecklistsAccepted = 'AllChecklistsAccepted',
  SendReportForConfirmationToCustomer = 'SendReportForConfirmationToCustomer',
  SendReportForConfirmationToResponsible = 'SendReportForConfirmationToResponsible',
  AcceptAndSendReportForConfirmationToCustomer = 'AcceptAndSendReportForConfirmationToCustomer',
  SendToTranslate = 'SendToTranslate',
  AcceptToTranslate = 'AcceptToTranslate',
  SendTranslation = 'SendTranslation',
  AcceptReport = 'AcceptReport',
  RejectReport = 'RejectReport',
}

export interface ScheduledWorkOrderActionEnumResponse {
  value: ScheduledWorkOrderAction;
  /** @minLength 1 */
  label: string;
}

export interface ScheduledWorkOrderAttachedDocumentRequest {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
  fileName?: string | null;
}

export interface ScheduledWorkOrderCommentResponse {
  /** @minLength 1 */
  comment: string;
  action: ScheduledWorkOrderAction;
  /** @format date-time */
  created: string;
}

export interface ScheduledWorkOrderEquipmentUsedRequest {
  /** @format int64 */
  equipmentId: number;
}

export interface ScheduledWorkOrderEventResponse {
  actorFullName?: string | null;
  /** @format date-time */
  created: string;
  /** @minLength 1 */
  statusFrom: string;
  /** @minLength 1 */
  statusTo: string;
  /** @minLength 1 */
  action: string;
  comment?: string | null;
}

export enum ScheduledWorkOrderExecuteStatus {
  Pending = 'Pending',
  InProgress = 'InProgress',
  ExpiredByChecklist = 'ExpiredByChecklist',
  ExpiredByReport = 'ExpiredByReport',
  SuccessfullyClosed = 'SuccessfullyClosed',
}

export interface ScheduledWorkOrderFileResponse {
  /** @format int64 */
  id: number;
  type: FileType;
  /** @minLength 1 */
  name: string;
  extension: FileExtension;
  /** @format int64 */
  size: number;
}

export enum ScheduledWorkOrderFileType {
  Common = 'Common',
  Report = 'Report',
}

export enum ScheduledWorkOrderGlobalStatus {
  Undefined = 'Undefined',
  Pending = 'Pending',
  InWork = 'InWork',
  AwaitAccept = 'AwaitAccept',
  Closed = 'Closed',
}

export interface ScheduledWorkOrderGlobalStatusEnumResponse {
  value: ScheduledWorkOrderGlobalStatus;
  /** @minLength 1 */
  label: string;
}

export interface ScheduledWorkOrderInvolvedPersonnelRequest {
  /** @format int64 */
  userId: number;
}

export interface ScheduledWorkOrderRegulatoryDocumentRequest {
  /** @format int64 */
  regulatoryDocumentId: number;
}

export interface ScheduledWorkOrderResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  scheduledWorkCode: string;
  subjectFullName?: string | null;
  /** @minLength 1 */
  description: string;
  plannedDate?: string | null;
  actualDate?: string | null;
  /** @format date-time */
  dateProvidingReport?: string | null;
  workPeriod: ScheduledWorkPeriodWebModel;
  status: ScheduledWorkOrderStatus;
  globalStatus: ScheduledWorkOrderGlobalStatus;
  executeStatus: ScheduledWorkOrderExecuteStatus;
  customer?: ScheduledTaskUserResponse;
  responsible?: ScheduledTaskUserResponse;
  performer?: ScheduledTaskUserResponse;
  scheduledTasks?: SlimScheduledTaskResponse[] | null;
  permittedTriggers?: ScheduledWorkOrderAction[] | null;
  actionResponsibleUserIds?: number[] | null;
  files?: ScheduledWorkOrderFileResponse[] | null;
  comments?: ScheduledWorkOrderCommentResponse[] | null;
  events: ScheduledWorkOrderEventResponse[];
  /** @format int64 */
  parentScheduledWorkOrderId?: number | null;
  parentScheduledWorkOrderReportName?: string | null;
}

export interface ScheduledWorkOrderStatisticInfoResponse {
  /** @format int64 */
  numberPlannedTasksPerMonthSelectedPeriod: number;
  /** @format int64 */
  numberPlannedTasksPerContractPeriod: number;
  /** @format int64 */
  numberReportsPerSelectedPeriod: number;
  /** @format int64 */
  pendingLp: number;
  /** @format int64 */
  pendingCs: number;
  /** @format int64 */
  inWorkLp: number;
  /** @format int64 */
  inWorkCs: number;
  /** @format int64 */
  closedLp: number;
  /** @format int64 */
  closedCs: number;
  /** @format int64 */
  expiredLp: number;
  /** @format int64 */
  expiredCs: number;
}

export enum ScheduledWorkOrderStatus {
  Created = 'Created',
  CreatedFromWeeklyPlan = 'CreatedFromWeeklyPlan',
  MonthlyPlan = 'MonthlyPlan',
  ChecklistsPendingConfirmation = 'ChecklistsPendingConfirmation',
  ChecklistConfirmed = 'ChecklistConfirmed',
  AwaitingReportAcceptanceFromResponsible = 'AwaitingReportAcceptanceFromResponsible',
  AwaitingAssignTranslator = 'AwaitingAssignTranslator',
  Translate = 'Translate',
  AwaitingReportAcceptanceFromCustomer = 'AwaitingReportAcceptanceFromCustomer',
  ReportAccepted = 'ReportAccepted',
}

export interface ScheduledWorkOrderStatusEnumResponse {
  value: ScheduledWorkOrderStatus;
  /** @minLength 1 */
  label: string;
}

export interface ScheduledWorkOrderTechniqueResourceRequest {
  /** @format int64 */
  techniqueResourceId: number;
}

export interface ScheduledWorkOrderUnscheduledTaskRequest {
  title?: string | null;
  description?: string | null;
  /**
   * @format date
   * @example "2023-12-31"
   */
  date?: string | null;
}

export interface ScheduledWorkOrderUsedMaterialRequest {
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  amount?: number;
  measuringUnit?: MeasuringUnit;
}

export interface ScheduledWorkOrderWebAttachedDocumentResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  fileName: string;
}

export interface ScheduledWorkOrderWebChecklistResponse {
  /** @minLength 1 */
  tag: string;
  /** @minLength 1 */
  documentName: string;
}

export interface ScheduledWorkOrderWebEquipmentUsedResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  tag: string;
  /** @minLength 1 */
  description: string;
}

export interface ScheduledWorkOrderWebInvolvedPersonnelResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  shortName: string;
}

export interface ScheduledWorkOrderWebRegulatoryDocumentResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  code: string;
}

export interface ScheduledWorkOrderWebReportInfoResponse {
  listOfUsedEquipment?: ScheduledWorkOrderWebEquipmentUsedResponse[] | null;
  listOfUsedMaterials?: ScheduledWorkOrderWebUsedMaterialResponse[] | null;
  listOfRegulatoryDocuments?: ScheduledWorkOrderWebRegulatoryDocumentResponse[] | null;
  listOfAttachedDocuments?: ScheduledWorkOrderWebAttachedDocumentResponse[] | null;
  listOfTechnique?: ScheduledWorkOrderWebTechniqueResourcesResponse[] | null;
  listOfInvolvedPersonnel?: ScheduledWorkOrderWebInvolvedPersonnelResponse[] | null;
  listOfUnscheduledTask?: ScheduledWorkOrderWebUnscheduledTaskResponse[] | null;
  /** @minLength 1 */
  conclusion: string;
}

export interface ScheduledWorkOrderWebResponse {
  /** @minLength 1 */
  sectionName: string;
  /** @minLength 1 */
  subjectName: string;
  /** @minLength 1 */
  revision: string;
  /** @minLength 1 */
  scheduledWorkDescriptionOnSerbian: string;
  /** @minLength 1 */
  scheduledWorkDescriptionOnRussian: string;
  /** @minLength 1 */
  reportFullName: string;
  /** @minLength 1 */
  plannedPeriodOnSerbian: string;
  /** @minLength 1 */
  plannedPeriodOnRussian: string;
  /** @minLength 1 */
  reportDate: string;
  /** @minLength 1 */
  startDateOfWork: string;
  /** @minLength 1 */
  endDateOfWork: string;
  checklistsConclusions?: string | null;
  performer: ScheduledWorkUserWebReportResponse;
  responsible: ScheduledWorkUserWebReportResponse;
  employee: ScheduledWorkUserWebReportResponse;
  reportInfo?: ScheduledWorkOrderWebReportInfoResponse;
  listOfChecklists?: ScheduledWorkOrderWebChecklistResponse[] | null;
  /** @minLength 1 */
  executorCompanyRole: string;
}

export interface ScheduledWorkOrderWebTechniqueResourcesResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
}

export interface ScheduledWorkOrderWebUnscheduledTaskResponse {
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  description: string;
  date?: string | null;
}

export interface ScheduledWorkOrderWebUsedMaterialResponse {
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  amount: number;
  measuringUnit: MeasuringUnit;
}

export enum ScheduledWorkPeriodWebModel {
  C = 'C',
  C2 = 'C2',
  M = 'M',
  M2 = 'M2',
  M3 = 'M3',
  M6 = 'M6',
  Y = 'Y',
  Y2 = 'Y2',
  Y3 = 'Y3',
  Y5 = 'Y5',
}

export interface ScheduledWorkPeriodWebModelEnumResponse {
  value: ScheduledWorkPeriodWebModel;
  /** @minLength 1 */
  label: string;
}

export interface ScheduledWorkResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  description: string;
}

export interface ScheduledWorkUserWebReportResponse {
  /** @minLength 1 */
  shortName: string;
  /** @minLength 1 */
  position: string;
  /** @minLength 1 */
  companyCode: string;
}

export interface SectionCommonInfoResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
  path?: string | null;
  disciplines?: DisciplineCommonInfoResponse[] | null;
}

export interface ServiceProductResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
}

export interface ServiceUnitNodeResponse {
  /** @format int64 */
  id?: number;
  nodePath?: string | null;
}

export interface ServiceUnitResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
  serialNumber?: string | null;
  manufacturer?: string | null;
  description?: string | null;
  equipmentGroup?: string | null;
  node?: ServiceUnitNodeResponse;
  tags?: TagResponse[] | null;
  files?: SlimServiceUnitFileResponse[] | null;
  availableForCompanies?: SlimCompanyResponse[] | null;
}

export interface SlimCompanyResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
}

export interface SlimEmergencyTaskResponse {
  /** @format int64 */
  id: number;
  highPriority: boolean;
  /** @format date-time */
  created: string;
  /** @format date-time */
  closingTime?: string | null;
  /** @minLength 1 */
  title: string;
  globalStatus: EmergencyTaskGlobalStatus;
  status: EmergencyTaskStatus;
  /** @minLength 1 */
  subjectFullName: string;
  author: SlimEmergencyTaskUserResponse;
  performer: SlimEmergencyTaskUserResponse;
  responsibleUserForAction: SlimEmergencyTaskUserResponse;
  createdFromUnscheduledTask?: boolean;
}

export interface SlimEmergencyTaskResponsePaginationResponse {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  data: SlimEmergencyTaskResponse[];
}

export interface SlimEmergencyTaskUserResponse {
  /** @minLength 1 */
  shortName: string;
  /** @minLength 1 */
  companyName: string;
}

export interface SlimScheduledTaskResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  subjectFullName: string;
  /** @minLength 1 */
  scheduledWorkCode: string;
  actualDate?: string | null;
  status: ScheduledTaskStatus;
  actionResponsibleUser?: SlimScheduledTaskUserResponse;
  tagOrLabel?: string | null;
  subsystemName?: string | null;
}

export interface SlimScheduledTaskUserResponse {
  /** @minLength 1 */
  shortName: string;
  /** @minLength 1 */
  companyName: string;
}

export interface SlimServiceUnitFileResponse {
  /** @format int64 */
  id?: number;
  type?: FileType;
  name?: string | null;
  /** @format int64 */
  size?: number;
  extension?: FileExtension;
  user?: SlimServiceUnitUserResponse;
}

export interface SlimServiceUnitResponse {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  fileCount?: number;
  name?: string | null;
  tags?: string[] | null;
  node?: ServiceUnitNodeResponse;
}

export interface SlimServiceUnitResponsePaginationResponse {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  data: SlimServiceUnitResponse[];
}

export interface SlimServiceUnitUserResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
  email?: string | null;
}

export interface SlimUnscheduledTaskResponse {
  /** @format int64 */
  id: number;
  /** @format date-time */
  dateOfCreation: string;
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  description: string;
  /** @format int64 */
  parentScheduledWorkOrderId: number;
  /** @minLength 1 */
  parentScheduledWorkOrderDocumentName: string;
  globalStatus: UnscheduledTaskGlobalStatus;
  status: UnscheduledTaskStatus;
  /** @minLength 1 */
  projectPhase: string;
  /** @minLength 1 */
  object: string;
  /** @minLength 1 */
  employer: string;
  commentEmployer?: string | null;
  responsible?: string | null;
  executor?: string | null;
  date?: string | null;
  plannedDate?: string | null;
  actualDate?: string | null;
  /** @format int64 */
  emergencyTaskId?: number | null;
  /** @format int64 */
  scheduledWorkOrderId?: number | null;
  /** @format date-time */
  closingTime?: string | null;
  outgoingReport?: string | null;
}

export interface SlimUnscheduledTaskResponsePaginationResponse {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  data: SlimUnscheduledTaskResponse[];
}

export interface SlimUserResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  email: string;
  company: SlimCompanyResponse;
}

export enum StatisticCountType {
  ScheduledWorkOrder = 'ScheduledWorkOrder',
  EmergencyTask = 'EmergencyTask',
  UnscheduledTask = 'UnscheduledTask',
}

export interface StatisticInfoResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  startDate: string;
  /** @minLength 1 */
  endDate: string;
  /** @format int32 */
  accidentsNumber: number;
  /** @format int32 */
  ecologyQuestionsNumber: number;
  plannedPeriods: PlannedPeriodResponse[];
}

export interface SubjectResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
  fullName?: string | null;
  subObjects?: SubjectResponse[] | null;
}

export interface TagCreateRequest {
  name?: string | null;
}

export interface TagResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
}

export interface TechniqueResourcesResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
  description?: string | null;
}

export interface TranslationEmergencyTaskWorkResponse {
  /** @format uuid */
  id: string;
  completedWork: TranslationFieldResponse;
}

export interface TranslationFieldResponse {
  /** @minLength 1 */
  origin: string;
  serbian?: string | null;
  russian?: string | null;
}

export interface TranslationScheduledTaskConclusionResponse {
  /** @format int64 */
  id: number;
  conclusion: TranslationFieldResponse;
}

export enum TranslationTaskAction {
  AcceptToTranslate = 'AcceptToTranslate',
  SendTranslation = 'SendTranslation',
}

export interface TranslationTaskResponse {
  /** @format int64 */
  id: number;
  type: TranslationTaskType;
  status: TranslationTaskStatus;
  conclusion?: TranslationFieldResponse;
  emergencyTaskWorks?: TranslationEmergencyTaskWorkResponse[] | null;
  scheduledTaskConclusions?: TranslationScheduledTaskConclusionResponse[] | null;
  permittedTrigger: TranslationTaskAction;
}

export enum TranslationTaskStatus {
  AwaitingAssignTranslator = 'AwaitingAssignTranslator',
  Translate = 'Translate',
}

export enum TranslationTaskType {
  EmergencyTask = 'EmergencyTask',
  ScheduledWorkOrder = 'ScheduledWorkOrder',
}

export interface TreeNodeResponse {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  parentId?: number | null;
  name?: string | null;
  path?: string | null;
  nodeTreeType?: string | null;
  children?: TreeNodeResponse[] | null;
  availableFileExtensions?: string[] | null;
  canStoreFiles?: boolean;
}

export enum UnscheduledTaskAction {
  Create = 'Create',
  CreateFromScheduledWorkOrderReport = 'CreateFromScheduledWorkOrderReport',
  ManualClose = 'ManualClose',
  AutoTransferToEmergencyTask = 'AutoTransferToEmergencyTask',
  ManualTransferToScheduledWorkOrder = 'ManualTransferToScheduledWorkOrder',
  ManualTransferToEmergencyTask = 'ManualTransferToEmergencyTask',
}

export interface UnscheduledTaskFileResponse {
  /** @format int64 */
  id?: number;
  type?: FileType;
  name?: string | null;
  extension?: FileExtension;
  /** @format int64 */
  size?: number;
}

export enum UnscheduledTaskGlobalStatus {
  Undefined = 'Undefined',
  Open = 'Open',
  Closed = 'Closed',
}

export interface UnscheduledTaskResponse {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  emergencyTaskId?: number | null;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  closingTime?: string | null;
  title?: string | null;
  description?: string | null;
  /** @format int64 */
  subjectId?: number;
  globalStatus?: UnscheduledTaskGlobalStatus;
  status?: UnscheduledTaskStatus;
  author?: UnscheduledTaskUserResponse;
  permittedTriggers?: UnscheduledTaskAction[] | null;
  /** @format date-time */
  updated?: string | null;
  /** @format int64 */
  actionResponsibleUserId?: number | null;
  performer?: UnscheduledTaskUserResponse;
  events?: UnscheduledTaskStageResponse[] | null;
}

export interface UnscheduledTaskStageResponse {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  actorId?: number;
  /** @format date-time */
  created?: string;
  statusFrom?: UnscheduledTaskStatus;
  statusTo?: UnscheduledTaskStatus;
  action?: UnscheduledTaskAction;
  text?: string | null;
  comment?: string | null;
  files?: UnscheduledTaskFileResponse[] | null;
}

export interface UnscheduledTaskStatisticResponse {
  /** @format int64 */
  numberCreatedInSelectedPeriodCs: number;
  /** @format int64 */
  numberPendingInSelectedPeriodCs: number;
  /** @format int64 */
  numberMovedToEmergencyTasksCs: number;
  /** @format int64 */
  numberMovedToScheduledWorkOrdersCs: number;
  /** @format int64 */
  numberClosedInSelectedPeriodCs: number;
  /** @format int64 */
  numberClosedInAnotherPeriodCs: number;
  /** @format int64 */
  numberCreatedInContractPeriodCs: number;
  /** @format int64 */
  numberClosedInContractPeriodCs: number;
  /** @format int64 */
  numberCreatedInSelectedPeriodLp: number;
  /** @format int64 */
  numberPendingInSelectedPeriodLp: number;
  /** @format int64 */
  numberMovedToEmergencyTasksLp: number;
  /** @format int64 */
  numberMovedToScheduledWorkOrdersLp: number;
  /** @format int64 */
  numberClosedInSelectedPeriodLp: number;
  /** @format int64 */
  numberClosedInAnotherPeriodLp: number;
  /** @format int64 */
  numberCreatedInContractPeriodLp: number;
  /** @format int64 */
  numberClosedInContractPeriodLp: number;
}

export enum UnscheduledTaskStatus {
  Initialized = 'Initialized',
  Open = 'Open',
  AutoTransferredToEmergencyTask = 'AutoTransferredToEmergencyTask',
  ManualClosed = 'ManualClosed',
  ManualTransferredToScheduledWorkOrder = 'ManualTransferredToScheduledWorkOrder',
  ManualTransferredToEmergencyTask = 'ManualTransferredToEmergencyTask',
}

export interface UnscheduledTaskUpdateRequest {
  action: UnscheduledTaskAction;
  comment?: string | null;
  /**
   * @format date
   * @example "2023-12-31"
   */
  plannedDate?: string | null;
}

export interface UnscheduledTaskUserResponse {
  /** @format int64 */
  id?: number;
  name?: string | null;
  surname?: string | null;
  companyName?: string | null;
}

/** Company update model. */
export interface UpdateCompanyRequest {
  /**
   * Company code
   * @minLength 2
   * @maxLength 30
   */
  code?: string | null;
  /**
   * RGB color.
   * @format int32
   */
  rgbColor?: number | null;
  role?: CompanyRoleType;
  /**
   * Email.
   * @format email
   */
  email?: string | null;
  /**
   * Phone.
   * @format tel
   */
  phone?: string | null;
  /**
   * Address.
   * @maxLength 100
   */
  address?: string | null;
  /**
   * Description.
   * @maxLength 100
   */
  description?: string | null;
}

export interface UpdateEmergencyTaskRequest {
  action?: EmergencyTaskAction;
  comment?: string | null;
  /** @format int64 */
  performerId?: number | null;
  /** @format int64 */
  purchasingManagerId?: number | null;
  /**
   * @format date
   * @example "2023-12-31"
   */
  date?: string | null;
  filesIds?: number[] | null;
  userIds?: number[] | null;
  repairType?: EmergencyTaskRepairType;
  listOfUsedEquipment?: EquipmentUsed[] | null;
  listOfUsedMaterials?: UsedMaterial[] | null;
  listOfRegulatoryDocuments?: RegulatoryDocument[] | null;
  listOfAttachedDocuments?: AttachedDocument[] | null;
  listOfWorks?: WorkRequest[] | null;
  conclusion?: string | null;
}

export interface UpdateNotificationRequest {
  viewed?: boolean;
}

export interface UpdatePlannedPeriodRequest {
  /** @format int64 */
  id: number;
  /** @format int32 */
  plannedReportCount: number;
}

export interface UpdateServiceUnitRequest {
  companiesIds?: number[] | null;
  tagsIds?: number[] | null;
  description?: string | null;
}

export interface UpdateStatisticInfoRequest {
  /**
   * @format date
   * @example "2023-12-31"
   */
  startDate?: string | null;
  /**
   * @format date
   * @example "2023-12-31"
   */
  endDate?: string | null;
  /** @format int32 */
  accidentsNumber?: number | null;
  /** @format int32 */
  ecologyQuestionsNumber?: number | null;
  plannedPeriods?: UpdatePlannedPeriodRequest[] | null;
}

export interface UpdateTranslationEmergencyTaskWorkRequest {
  /** @format uuid */
  id: string;
  completedWork: UpdateTranslationFieldRequest;
}

export interface UpdateTranslationFieldRequest {
  serbian?: string | null;
  russian?: string | null;
}

export interface UpdateTranslationScheduledTaskConclusionRequest {
  /** @format int64 */
  id: number;
  conclusion: UpdateTranslationFieldRequest;
}

export interface UpdateTranslationTaskRequest {
  type: TranslationTaskType;
  action: TranslationTaskAction;
  conclusion?: UpdateTranslationFieldRequest;
  emergencyTaskWorks?: UpdateTranslationEmergencyTaskWorkRequest[] | null;
  scheduledTaskConclusions?: UpdateTranslationScheduledTaskConclusionRequest[] | null;
}

export interface UpdateUserRequest {
  /**
   * @minLength 3
   * @maxLength 20
   */
  name?: string | null;
  /**
   * @minLength 3
   * @maxLength 20
   */
  surname?: string | null;
  activityStatus?: boolean | null;
  canBeResponsible?: boolean | null;
  role?: UserRoleType;
  /** @format int64 */
  companyId?: number | null;
  /** @format int64 */
  positionId?: number | null;
  disciplines?: DisciplineType[] | null;
}

export interface UsedMaterial {
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  amount?: number;
  measuringUnit?: MeasuringUnit;
}

export interface UserResponse {
  /** @format int64 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  surname: string;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  phone: string;
  /** @format byte */
  profilePhotoBlobData?: string | null;
  activityStatus: boolean;
  canBeResponsible: boolean;
  emailAddressConfirmed: boolean;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  role: UserRoleType;
  company: CompanyResponse;
  /** @minLength 1 */
  positionName: string;
  disciplines?: DisciplineResponse[] | null;
}

export enum UserRoleType {
  Admin = 'Admin',
  Customer = 'Customer',
  GeneralContractor = 'GeneralContractor',
  GeneralContractorSupervisor = 'GeneralContractorSupervisor',
  Subcontractor = 'Subcontractor',
  SecurityService = 'SecurityService',
  Translator = 'Translator',
}

export interface UserRoleTypeEnumResponse {
  value: UserRoleType;
  /** @minLength 1 */
  label: string;
}

export interface WorkRequest {
  objectName?: string | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(key => 'undefined' !== typeof query[key]);
    return keys
      .map(key => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async response => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then(data => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch(e => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title TOIR API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Auth
     * @name V1AuthLoginCreate
     * @summary Login
     * @request POST:/api/v1/auth/login
     * @secure
     */
    v1AuthLoginCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponse, IdcProblemDetail>({
        path: `/api/v1/auth/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name V1AuthRefreshCreate
     * @summary Refresh token
     * @request POST:/api/v1/auth/refresh
     * @secure
     */
    v1AuthRefreshCreate: (data: RefreshTokenRequest, params: RequestParams = {}) =>
      this.request<LoginResponse, any>({
        path: `/api/v1/auth/refresh`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name V1CompaniesList
     * @summary Get all companies.
     * @request GET:/api/v1/companies
     * @secure
     */
    v1CompaniesList: (params: RequestParams = {}) =>
      this.request<CompanyResponse[], any>({
        path: `/api/v1/companies`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name V1CompaniesCreate
     * @summary Create new company.
     * @request POST:/api/v1/companies
     * @secure
     */
    v1CompaniesCreate: (data: CreateCompanyRequest, params: RequestParams = {}) =>
      this.request<CompanyResponse, IdcProblemDetail>({
        path: `/api/v1/companies`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name V1CompaniesDetail
     * @summary Get company by identifier.
     * @request GET:/api/v1/companies/{companyId}
     * @secure
     */
    v1CompaniesDetail: (companyId: number, params: RequestParams = {}) =>
      this.request<CompanyResponse, IdcProblemDetail>({
        path: `/api/v1/companies/${companyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name V1CompaniesUpdate
     * @summary Update company.
     * @request PUT:/api/v1/companies/{companyId}
     * @secure
     */
    v1CompaniesUpdate: (companyId: number, data: UpdateCompanyRequest, params: RequestParams = {}) =>
      this.request<CompanyResponse, IdcProblemDetail>({
        path: `/api/v1/companies/${companyId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name V1CompaniesRolesList
     * @summary Get all user roles.
     * @request GET:/api/v1/companies/roles
     * @secure
     */
    v1CompaniesRolesList: (params: RequestParams = {}) =>
      this.request<CompanyRoleTypeEnumResponse[], any>({
        path: `/api/v1/companies/roles`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksCreate
     * @summary Create emergency task
     * @request POST:/api/v1/emergency-tasks
     * @secure
     */
    v1EmergencyTasksCreate: (data: CreateEmergencyTaskRequest, params: RequestParams = {}) =>
      this.request<EmergencyTaskCreationResponse, IdcProblemDetail>({
        path: `/api/v1/emergency-tasks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksList
     * @summary Get all emergency tasks for the current user.
     * @request GET:/api/v1/emergency-tasks
     * @secure
     */
    v1EmergencyTasksList: (
      query?: {
        CompaniesIds?: number[];
        Statuses?: EmergencyTaskGlobalStatus[];
        SubjectsIds?: number[];
        SubjectWithChildIds?: number[];
        /**
         * @format date
         * @example "2023-12-31"
         */
        From?: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        To?: string;
        IsAwaitingUserAction?: boolean;
        IsAwaitingCompanyAction?: boolean;
        UserIds?: number[];
        OnlyAcceptedOffer?: boolean;
        OnlyCreatedFromUnscheduledTask?: boolean;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<SlimEmergencyTaskResponsePaginationResponse, IdcProblemDetail>({
        path: `/api/v1/emergency-tasks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksUpdate
     * @summary Update emergency task
     * @request PUT:/api/v1/emergency-tasks/{emergencyTaskId}
     * @secure
     */
    v1EmergencyTasksUpdate: (emergencyTaskId: number, data: UpdateEmergencyTaskRequest, params: RequestParams = {}) =>
      this.request<EmergencyTaskResponse, IdcProblemDetail>({
        path: `/api/v1/emergency-tasks/${emergencyTaskId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksDelete
     * @summary Delete emergency task
     * @request DELETE:/api/v1/emergency-tasks/{emergencyTaskId}
     * @secure
     */
    v1EmergencyTasksDelete: (emergencyTaskId: number, params: RequestParams = {}) =>
      this.request<void, IdcProblemDetail>({
        path: `/api/v1/emergency-tasks/${emergencyTaskId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksDetail
     * @summary Get emergency task information
     * @request GET:/api/v1/emergency-tasks/{emergencyTaskId}
     * @secure
     */
    v1EmergencyTasksDetail: (emergencyTaskId: number, params: RequestParams = {}) =>
      this.request<EmergencyTaskResponse, IdcProblemDetail>({
        path: `/api/v1/emergency-tasks/${emergencyTaskId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksReportDetail
     * @summary Get emergency task report
     * @request GET:/api/v1/emergency-tasks/{emergencyTaskId}/report
     * @secure
     */
    v1EmergencyTasksReportDetail: (emergencyTaskId: number, params: RequestParams = {}) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/emergency-tasks/${emergencyTaskId}/report`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksReportWebDetail
     * @summary Get emergency task web report.
     * @request GET:/api/v1/emergency-tasks/{emergencyTaskId}/report/web
     * @secure
     */
    v1EmergencyTasksReportWebDetail: (emergencyTaskId: number, params: RequestParams = {}) =>
      this.request<EmergencyTaskWebReportResponse, IdcProblemDetail>({
        path: `/api/v1/emergency-tasks/${emergencyTaskId}/report/web`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksStatesList
     * @summary Get emergency task states
     * @request GET:/api/v1/emergency-tasks/states
     * @secure
     */
    v1EmergencyTasksStatesList: (params: RequestParams = {}) =>
      this.request<EmergencyTaskResponse[], IdcProblemDetail>({
        path: `/api/v1/emergency-tasks/states`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksFilesCreate
     * @summary Upload emergency task files
     * @request POST:/api/v1/emergency-tasks/files
     * @secure
     */
    v1EmergencyTasksFilesCreate: (params: RequestParams = {}) =>
      this.request<EmergencyTaskFileResponse[], IdcProblemDetail>({
        path: `/api/v1/emergency-tasks/files`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksFilesDelete
     * @summary Delete emergency task file
     * @request DELETE:/api/v1/emergency-tasks/files/{fileId}
     * @secure
     */
    v1EmergencyTasksFilesDelete: (fileId: number, params: RequestParams = {}) =>
      this.request<void, IdcProblemDetail>({
        path: `/api/v1/emergency-tasks/files/${fileId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksArchiveList
     * @summary Download emergency tasks archive
     * @request GET:/api/v1/emergency-tasks/archive
     * @secure
     */
    v1EmergencyTasksArchiveList: (
      query?: {
        CompaniesIds?: number[];
        Statuses?: EmergencyTaskGlobalStatus[];
        SubjectsIds?: number[];
        SubjectWithChildIds?: number[];
        /**
         * @format date
         * @example "2023-12-31"
         */
        From?: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        To?: string;
        IsAwaitingUserAction?: boolean;
        IsAwaitingCompanyAction?: boolean;
        UserIds?: number[];
        OnlyAcceptedOffer?: boolean;
        OnlyCreatedFromUnscheduledTask?: boolean;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/v1/emergency-tasks/archive`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksFilesRegistryList
     * @summary Get emergency tasks registry
     * @request GET:/api/v1/emergency-tasks/files/registry
     * @secure
     */
    v1EmergencyTasksFilesRegistryList: (
      query?: {
        CompaniesIds?: number[];
        Statuses?: EmergencyTaskGlobalStatus[];
        SubjectsIds?: number[];
        SubjectWithChildIds?: number[];
        /**
         * @format date
         * @example "2023-12-31"
         */
        From?: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        To?: string;
        IsAwaitingUserAction?: boolean;
        IsAwaitingCompanyAction?: boolean;
        UserIds?: number[];
        OnlyAcceptedOffer?: boolean;
        OnlyCreatedFromUnscheduledTask?: boolean;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/v1/emergency-tasks/files/registry`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksRegistryList
     * @summary Get emergency tasks registry
     * @request GET:/api/v1/emergency-tasks/registry
     * @secure
     */
    v1EmergencyTasksRegistryList: (
      query?: {
        CompaniesIds?: number[];
        Statuses?: EmergencyTaskGlobalStatus[];
        SubjectsIds?: number[];
        SubjectWithChildIds?: number[];
        /**
         * @format date
         * @example "2023-12-31"
         */
        From?: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        To?: string;
        IsAwaitingUserAction?: boolean;
        IsAwaitingCompanyAction?: boolean;
        UserIds?: number[];
        OnlyAcceptedOffer?: boolean;
        OnlyCreatedFromUnscheduledTask?: boolean;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmergencyTaskRegistryResponse[], any>({
        path: `/api/v1/emergency-tasks/registry`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmergencyTasks
     * @name V1EmergencyTasksCardDetail
     * @summary Download emergency task card
     * @request GET:/api/v1/emergency-tasks/{emergencyTaskId}/card
     * @secure
     */
    v1EmergencyTasksCardDetail: (emergencyTaskId: number, params: RequestParams = {}) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/emergency-tasks/${emergencyTaskId}/card`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enums
     * @name V1EnumsEmergencyTaskActionList
     * @summary Get emergency task actions
     * @request GET:/api/v1/enums/emergency-task-action
     * @secure
     */
    v1EnumsEmergencyTaskActionList: (params: RequestParams = {}) =>
      this.request<EmergencyTaskActionEnumResponse[], any>({
        path: `/api/v1/enums/emergency-task-action`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enums
     * @name V1EnumsEmergencyTaskStatusList
     * @summary Get emergency task status
     * @request GET:/api/v1/enums/emergency-task-status
     * @secure
     */
    v1EnumsEmergencyTaskStatusList: (params: RequestParams = {}) =>
      this.request<EmergencyTaskStatusEnumResponse[], any>({
        path: `/api/v1/enums/emergency-task-status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enums
     * @name V1EnumsScheduledTaskActionList
     * @summary Get scheduled task actions
     * @request GET:/api/v1/enums/scheduled-task-action
     * @secure
     */
    v1EnumsScheduledTaskActionList: (params: RequestParams = {}) =>
      this.request<ScheduledTaskActionEnumResponse[], any>({
        path: `/api/v1/enums/scheduled-task-action`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enums
     * @name V1EnumsScheduledTaskStatusList
     * @summary Get scheduled task status
     * @request GET:/api/v1/enums/scheduled-task-status
     * @secure
     */
    v1EnumsScheduledTaskStatusList: (params: RequestParams = {}) =>
      this.request<ScheduledTaskStatusEnumResponse[], any>({
        path: `/api/v1/enums/scheduled-task-status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enums
     * @name V1EnumsScheduledWorkOrderActionList
     * @summary Get scheduled task actions
     * @request GET:/api/v1/enums/scheduled-work-order-action
     * @secure
     */
    v1EnumsScheduledWorkOrderActionList: (params: RequestParams = {}) =>
      this.request<ScheduledWorkOrderActionEnumResponse[], any>({
        path: `/api/v1/enums/scheduled-work-order-action`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enums
     * @name V1EnumsScheduledWorkOrderStatusList
     * @summary Get scheduled work order status
     * @request GET:/api/v1/enums/scheduled-work-order-status
     * @secure
     */
    v1EnumsScheduledWorkOrderStatusList: (params: RequestParams = {}) =>
      this.request<ScheduledWorkOrderStatusEnumResponse[], any>({
        path: `/api/v1/enums/scheduled-work-order-status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enums
     * @name V1EnumsScheduledWorkOrderGlobalStatusList
     * @summary Get scheduled work order global status
     * @request GET:/api/v1/enums/scheduled-work-order-global-status
     * @secure
     */
    v1EnumsScheduledWorkOrderGlobalStatusList: (params: RequestParams = {}) =>
      this.request<ScheduledWorkOrderGlobalStatusEnumResponse[], any>({
        path: `/api/v1/enums/scheduled-work-order-global-status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enums
     * @name V1EnumsMeasuringUnitsList
     * @summary Get measuring units
     * @request GET:/api/v1/enums/measuring-units
     * @secure
     */
    v1EnumsMeasuringUnitsList: (params: RequestParams = {}) =>
      this.request<MeasuringUnitEnumResponse[], any>({
        path: `/api/v1/enums/measuring-units`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enums
     * @name V1EnumsScheduledWorkPeriodsList
     * @summary Get scheduled work periods web model
     * @request GET:/api/v1/enums/scheduled-work-periods
     * @secure
     */
    v1EnumsScheduledWorkPeriodsList: (params: RequestParams = {}) =>
      this.request<ScheduledWorkPeriodWebModelEnumResponse[], any>({
        path: `/api/v1/enums/scheduled-work-periods`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipments
     * @name V1EquipmentsList
     * @summary Find equipments by tag or description
     * @request GET:/api/v1/equipments
     * @secure
     */
    v1EquipmentsList: (
      query?: {
        Tag?: string;
        Description?: string;
        /** @format int32 */
        Take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<EquipmentResponse[], IdcProblemDetail>({
        path: `/api/v1/equipments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipments
     * @name V1EquipmentsCreate
     * @summary Create equipment
     * @request POST:/api/v1/equipments
     * @secure
     */
    v1EquipmentsCreate: (data: CreateEquipmentRequest, params: RequestParams = {}) =>
      this.request<EquipmentResponse, IdcProblemDetail>({
        path: `/api/v1/equipments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipments
     * @name V1EquipmentsLibraryList
     * @summary Get all equipments
     * @request GET:/api/v1/equipments/library
     * @secure
     */
    v1EquipmentsLibraryList: (
      query?: {
        /** @format int64 */
        CompanyId?: number;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<EquipmentResponsePaginationResponse, IdcProblemDetail>({
        path: `/api/v1/equipments/library`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name V1FilesNodesCreate
     * @summary Upload files into node.
     * @request POST:/api/v1/files/nodes/{nodeId}
     * @secure
     */
    v1FilesNodesCreate: (nodeId: number, params: RequestParams = {}) =>
      this.request<FileResponse[], IdcProblemDetail>({
        path: `/api/v1/files/nodes/${nodeId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name V1FilesSmartCreate
     * @summary Smart upload files
     * @request POST:/api/v1/files/smart
     * @secure
     */
    v1FilesSmartCreate: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<FileResponse, IdcProblemDetail>({
        path: `/api/v1/files/smart`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name V1FilesPlansList
     * @summary Get plan file
     * @request GET:/api/v1/files/plans
     * @secure
     */
    v1FilesPlansList: (
      query?: {
        Date?: string;
        ProjectPhase?: string;
        PlanPeriod?: PlanPeriod;
      },
      params: RequestParams = {},
    ) =>
      this.request<PlanFileResponse[], any>({
        path: `/api/v1/files/plans`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name V1FilesPlansArchiveList
     * @summary Get plan files archive
     * @request GET:/api/v1/files/plans/archive
     * @secure
     */
    v1FilesPlansArchiveList: (
      query?: {
        Date?: string;
        ProjectPhase?: string;
        PlanPeriod?: PlanPeriod;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/v1/files/plans/archive`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name V1FilesPlansDelete
     * @summary Delete plan file
     * @request DELETE:/api/v1/files/plans/{planFileId}
     * @secure
     */
    v1FilesPlansDelete: (planFileId: number, params: RequestParams = {}) =>
      this.request<void, IdcProblemDetail>({
        path: `/api/v1/files/plans/${planFileId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name V1FilesProfilePhotosCreate
     * @summary Upload profile photo
     * @request POST:/api/v1/files/profile-photos
     * @secure
     */
    v1FilesProfilePhotosCreate: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProfilePhotoResponse, IdcProblemDetail>({
        path: `/api/v1/files/profile-photos`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name V1FilesPublicDetail
     * @summary Download file
     * @request GET:/api/v1/files/public/{fileStorageId}
     * @secure
     */
    v1FilesPublicDetail: (
      fileStorageId: number,
      query?: {
        /**
         * Public token
         * @format uuid
         */
        token?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/files/public/${fileStorageId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name V1FilesDetail
     * @summary Download file
     * @request GET:/api/v1/files/{fileId}
     * @secure
     */
    v1FilesDetail: (
      fileId: number,
      query?: {
        /** File type */
        fileType?: FileType;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/files/${fileId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name V1FilesPdfDetail
     * @summary Download file as pdf
     * @request GET:/api/v1/files/pdf/{fileId}
     * @secure
     */
    v1FilesPdfDetail: (
      fileId: number,
      query?: {
        /** File type */
        fileType?: FileType;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/files/pdf/${fileId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name V1FilesImageDetail
     * @summary Download image file (jpg, jpeg or png)
     * @request GET:/api/v1/files/image/{fileId}
     * @secure
     */
    v1FilesImageDetail: (
      fileId: number,
      query?: {
        /** File type */
        fileType?: FileType;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/files/image/${fileId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name V1FilesSupportedExtensionsList
     * @summary Available file extensions
     * @request GET:/api/v1/files/supported-extensions
     * @secure
     */
    v1FilesSupportedExtensionsList: (params: RequestParams = {}) =>
      this.request<FileExtensionResponse[], any>({
        path: `/api/v1/files/supported-extensions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nodes
     * @name V1NodesCreate
     * @summary Create node
     * @request POST:/api/v1/nodes
     * @secure
     */
    v1NodesCreate: (data: CreateNodeRequest, params: RequestParams = {}) =>
      this.request<CreateNodeResponse, IdcProblemDetail>({
        path: `/api/v1/nodes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nodes
     * @name V1NodesList
     * @summary Find node by path
     * @request GET:/api/v1/nodes
     * @secure
     */
    v1NodesList: (
      query?: {
        /** Node path */
        nodePath?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<NodeResponse, IdcProblemDetail>({
        path: `/api/v1/nodes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nodes
     * @name V1NodesRootNodesList
     * @summary Get all tree
     * @request GET:/api/v1/nodes/root-nodes
     * @secure
     */
    v1NodesRootNodesList: (params: RequestParams = {}) =>
      this.request<TreeNodeResponse[], any>({
        path: `/api/v1/nodes/root-nodes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nodes
     * @name V1NodesDetail
     * @summary Get node by identifier
     * @request GET:/api/v1/nodes/{nodeId}
     * @secure
     */
    v1NodesDetail: (nodeId: number, params: RequestParams = {}) =>
      this.request<NodeResponse, IdcProblemDetail>({
        path: `/api/v1/nodes/${nodeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nodes
     * @name V1NodesServiceUnitsDetail
     * @summary Get service units on node
     * @request GET:/api/v1/nodes/{nodeId}/service-units
     * @secure
     */
    v1NodesServiceUnitsDetail: (
      nodeId: number,
      query?: {
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<SlimServiceUnitResponsePaginationResponse, IdcProblemDetail>({
        path: `/api/v1/nodes/${nodeId}/service-units`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Positions
     * @name V1PositionsDetail
     * @summary Get position information
     * @request GET:/api/v1/positions/{id}
     * @secure
     */
    v1PositionsDetail: (id: number, params: RequestParams = {}) =>
      this.request<PositionResponse, IdcProblemDetail>({
        path: `/api/v1/positions/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Positions
     * @name V1PositionsCreate
     * @summary Create position
     * @request POST:/api/v1/positions
     * @secure
     */
    v1PositionsCreate: (data: CreatePositionRequest, params: RequestParams = {}) =>
      this.request<PositionResponse, IdcProblemDetail>({
        path: `/api/v1/positions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Positions
     * @name V1PositionsList
     * @summary Get all positions
     * @request GET:/api/v1/positions
     * @secure
     */
    v1PositionsList: (params: RequestParams = {}) =>
      this.request<PositionResponse[], any>({
        path: `/api/v1/positions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RegulatoryDocuments
     * @name V1RegulatoryDocumentsList
     * @summary Find regulatory document by code or name
     * @request GET:/api/v1/regulatory-documents
     * @secure
     */
    v1RegulatoryDocumentsList: (
      query?: {
        Code?: string;
        Name?: string;
        /** @format int32 */
        Take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<RegulatoryDocumentResponse[], IdcProblemDetail>({
        path: `/api/v1/regulatory-documents`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RegulatoryDocuments
     * @name V1RegulatoryDocumentsCreate
     * @summary Create regulatory document
     * @request POST:/api/v1/regulatory-documents
     * @secure
     */
    v1RegulatoryDocumentsCreate: (data: CreateRegulatoryDocumentRequest, params: RequestParams = {}) =>
      this.request<RegulatoryDocumentResponse, IdcProblemDetail>({
        path: `/api/v1/regulatory-documents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RegulatoryDocuments
     * @name V1RegulatoryDocumentsLibraryList
     * @summary Get all regulatory documents
     * @request GET:/api/v1/regulatory-documents/library
     * @secure
     */
    v1RegulatoryDocumentsLibraryList: (
      query?: {
        /** @format int64 */
        CompanyId?: number;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<RegulatoryDocumentResponsePaginationResponse, IdcProblemDetail>({
        path: `/api/v1/regulatory-documents/library`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersList
     * @summary Get all scheduled work orders for the current user.
     * @request GET:/api/v1/scheduled-work-orders
     * @secure
     */
    v1ScheduledWorkOrdersList: (
      query?: {
        /** Companies identifiers. */
        CompaniesIds?: number[];
        /** Subjects identifiers. */
        SubjectsIds?: number[];
        /** Subjects with child identifiers. */
        SubjectWithChildIds?: number[];
        /** Scheduled work identifiers. */
        ScheduledWorkIds?: number[];
        /** Work periods. */
        WorkPeriods?: ScheduledWorkPeriodWebModel[];
        /** Global statuses. */
        GlobalStatuses?: ScheduledWorkOrderGlobalStatus[];
        /**
         * Date from.
         * @format date
         * @example "2023-12-31"
         */
        From?: string;
        /**
         * Date to.
         * @format date
         * @example "2023-12-31"
         */
        To?: string;
        /** Is awaiting user action. */
        IsAwaitingUserAction?: boolean;
        /** Is awaiting company action. */
        IsAwaitingCompanyAction?: boolean;
        /** User ids. */
        UserIds?: number[];
        /** Only created from unscheduled task. */
        OnlyCreatedFromUnscheduledTask?: boolean;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<BoardScheduledWorkOrderResponsePaginationResponse, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersDetail
     * @summary Get schedule work order information.
     * @request GET:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}
     * @secure
     */
    v1ScheduledWorkOrdersDetail: (scheduledWorkOrderId: number, params: RequestParams = {}) =>
      this.request<ScheduledWorkOrderResponse, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersUpdate
     * @summary Update scheduled task.
     * @request PUT:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}
     * @secure
     */
    v1ScheduledWorkOrdersUpdate: (
      scheduledWorkOrderId: number,
      data: ScheduleWorkOrderUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ScheduledWorkOrderResponse, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersDelete
     * @summary Delete schedule work order.
     * @request DELETE:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}
     * @secure
     */
    v1ScheduledWorkOrdersDelete: (scheduledWorkOrderId: number, params: RequestParams = {}) =>
      this.request<ScheduledWorkOrderResponse, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersReportDetail
     * @summary Download scheduled work order report.
     * @request GET:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}/report
     * @secure
     */
    v1ScheduledWorkOrdersReportDetail: (scheduledWorkOrderId: number, params: RequestParams = {}) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}/report`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersReportWebDetail
     * @summary Get scheduled work order web report.
     * @request GET:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}/report/web
     * @secure
     */
    v1ScheduledWorkOrdersReportWebDetail: (scheduledWorkOrderId: number, params: RequestParams = {}) =>
      this.request<ScheduledWorkOrderWebResponse, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}/report/web`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersCardDetail
     * @summary Download scheduled work order card.
     * @request GET:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}/card
     * @secure
     */
    v1ScheduledWorkOrdersCardDetail: (scheduledWorkOrderId: number, params: RequestParams = {}) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}/card`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersFilesCreate
     * @summary Upload scheduled work order files.
     * @request POST:/api/v1/scheduled-work-orders/{scheduleWorkOrderId}/files
     * @secure
     */
    v1ScheduledWorkOrdersFilesCreate: (
      scheduleWorkOrderId: number,
      query?: {
        /** Scheduled work order file type */
        fileType?: ScheduledWorkOrderFileType;
      },
      params: RequestParams = {},
    ) =>
      this.request<ScheduledWorkOrderFileResponse[], IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduleWorkOrderId}/files`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersFilesDelete
     * @summary Delete scheduled work order file.
     * @request DELETE:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}/files/{fileId}
     * @secure
     */
    v1ScheduledWorkOrdersFilesDelete: (scheduledWorkOrderId: number, fileId: number, params: RequestParams = {}) =>
      this.request<void, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}/files/${fileId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersScheduledTasksDetail
     * @summary Get schedule task information.
     * @request GET:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}/scheduled-tasks/{scheduledTaskId}
     * @secure
     */
    v1ScheduledWorkOrdersScheduledTasksDetail: (
      scheduledWorkOrderId: number,
      scheduledTaskId: number,
      params: RequestParams = {},
    ) =>
      this.request<ScheduledTaskResponse, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}/scheduled-tasks/${scheduledTaskId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersScheduledTasksUpdate
     * @summary Update scheduled task.
     * @request PUT:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}/scheduled-tasks/{scheduledTaskId}
     * @secure
     */
    v1ScheduledWorkOrdersScheduledTasksUpdate: (
      scheduledWorkOrderId: number,
      scheduledTaskId: number,
      data: ScheduleTaskUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ScheduledTaskResponse, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}/scheduled-tasks/${scheduledTaskId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersScheduledTasksDelete
     * @summary Delete schedule task.
     * @request DELETE:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}/scheduled-tasks/{scheduledTaskId}
     * @secure
     */
    v1ScheduledWorkOrdersScheduledTasksDelete: (
      scheduledWorkOrderId: number,
      scheduledTaskId: number,
      params: RequestParams = {},
    ) =>
      this.request<ScheduledTaskResponse, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}/scheduled-tasks/${scheduledTaskId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersScheduledTasksChecklistDetail
     * @summary Download schedule task checklist.
     * @request GET:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}/scheduled-tasks/{scheduledTaskId}/checklist
     * @secure
     */
    v1ScheduledWorkOrdersScheduledTasksChecklistDetail: (
      scheduledWorkOrderId: number,
      scheduledTaskId: number,
      params: RequestParams = {},
    ) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}/scheduled-tasks/${scheduledTaskId}/checklist`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersScheduledTasksChecklistPreviewCreate
     * @summary Download preview schedule task checklist.
     * @request POST:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}/scheduled-tasks/{scheduledTaskId}/checklist/preview
     * @secure
     */
    v1ScheduledWorkOrdersScheduledTasksChecklistPreviewCreate: (
      scheduledWorkOrderId: number,
      scheduledTaskId: number,
      data: PreviewScheduleTaskChecklistRequest,
      params: RequestParams = {},
    ) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}/scheduled-tasks/${scheduledTaskId}/checklist/preview`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersScheduledTasksFilesCreate
     * @summary Upload scheduled task files.
     * @request POST:/api/v1/scheduled-work-orders/{scheduleWorkOrderId}/scheduled-tasks/{scheduledTaskId}/files
     * @secure
     */
    v1ScheduledWorkOrdersScheduledTasksFilesCreate: (
      scheduleWorkOrderId: number,
      scheduledTaskId: number,
      query?: {
        /** Scheduled task file type */
        fileType?: ScheduledTaskFileType;
      },
      params: RequestParams = {},
    ) =>
      this.request<ScheduledWorkOrderFileResponse[], IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduleWorkOrderId}/scheduled-tasks/${scheduledTaskId}/files`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersScheduledTasksFilesDelete
     * @summary Delete scheduled task file.
     * @request DELETE:/api/v1/scheduled-work-orders/{scheduledWorkOrderId}/scheduled-tasks/{scheduledTaskId}/files/{fileId}
     * @secure
     */
    v1ScheduledWorkOrdersScheduledTasksFilesDelete: (
      scheduledWorkOrderId: number,
      scheduledTaskId: number,
      fileId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/${scheduledWorkOrderId}/scheduled-tasks/${scheduledTaskId}/files/${fileId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersArchiveList
     * @summary Download scheduled work orders archive.
     * @request GET:/api/v1/scheduled-work-orders/archive
     * @secure
     */
    v1ScheduledWorkOrdersArchiveList: (
      query?: {
        /** Companies identifiers. */
        CompaniesIds?: number[];
        /** Subjects identifiers. */
        SubjectsIds?: number[];
        /** Subjects with child identifiers. */
        SubjectWithChildIds?: number[];
        /** Scheduled work identifiers. */
        ScheduledWorkIds?: number[];
        /** Work periods. */
        WorkPeriods?: ScheduledWorkPeriodWebModel[];
        /** Global statuses. */
        GlobalStatuses?: ScheduledWorkOrderGlobalStatus[];
        /**
         * Date from.
         * @format date
         * @example "2023-12-31"
         */
        From?: string;
        /**
         * Date to.
         * @format date
         * @example "2023-12-31"
         */
        To?: string;
        /** Is awaiting user action. */
        IsAwaitingUserAction?: boolean;
        /** Is awaiting company action. */
        IsAwaitingCompanyAction?: boolean;
        /** User ids. */
        UserIds?: number[];
        /** Only created from unscheduled task. */
        OnlyCreatedFromUnscheduledTask?: boolean;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/archive`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersHumanResourcesReportList
     * @summary Download human resources report.
     * @request GET:/api/v1/scheduled-work-orders/human-resources-report
     * @secure
     */
    v1ScheduledWorkOrdersHumanResourcesReportList: (
      query?: {
        /** Companies identifiers. */
        CompaniesIds?: number[];
        /** Subjects identifiers. */
        SubjectsIds?: number[];
        /** Subjects with child identifiers. */
        SubjectWithChildIds?: number[];
        /** Scheduled work identifiers. */
        ScheduledWorkIds?: number[];
        /** Work periods. */
        WorkPeriods?: ScheduledWorkPeriodWebModel[];
        /** Global statuses. */
        GlobalStatuses?: ScheduledWorkOrderGlobalStatus[];
        /**
         * Date from.
         * @format date
         * @example "2023-12-31"
         */
        From?: string;
        /**
         * Date to.
         * @format date
         * @example "2023-12-31"
         */
        To?: string;
        /** Is awaiting user action. */
        IsAwaitingUserAction?: boolean;
        /** Is awaiting company action. */
        IsAwaitingCompanyAction?: boolean;
        /** User ids. */
        UserIds?: number[];
        /** Only created from unscheduled task. */
        OnlyCreatedFromUnscheduledTask?: boolean;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/human-resources-report`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersTechnicalResourcesReportList
     * @summary Download technical resources report.
     * @request GET:/api/v1/scheduled-work-orders/technical-resources-report
     * @secure
     */
    v1ScheduledWorkOrdersTechnicalResourcesReportList: (
      query?: {
        /** Companies identifiers. */
        CompaniesIds?: number[];
        /** Subjects identifiers. */
        SubjectsIds?: number[];
        /** Subjects with child identifiers. */
        SubjectWithChildIds?: number[];
        /** Scheduled work identifiers. */
        ScheduledWorkIds?: number[];
        /** Work periods. */
        WorkPeriods?: ScheduledWorkPeriodWebModel[];
        /** Global statuses. */
        GlobalStatuses?: ScheduledWorkOrderGlobalStatus[];
        /**
         * Date from.
         * @format date
         * @example "2023-12-31"
         */
        From?: string;
        /**
         * Date to.
         * @format date
         * @example "2023-12-31"
         */
        To?: string;
        /** Is awaiting user action. */
        IsAwaitingUserAction?: boolean;
        /** Is awaiting company action. */
        IsAwaitingCompanyAction?: boolean;
        /** User ids. */
        UserIds?: number[];
        /** Only created from unscheduled task. */
        OnlyCreatedFromUnscheduledTask?: boolean;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/technical-resources-report`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersScheduledWorksList
     * @summary Get scheduled works.
     * @request GET:/api/v1/scheduled-work-orders/scheduled-works
     * @secure
     */
    v1ScheduledWorkOrdersScheduledWorksList: (params: RequestParams = {}) =>
      this.request<ScheduledWorkResponse[], IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/scheduled-works`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduledWorkOrders
     * @name V1ScheduledWorkOrdersTechniqueResourcesList
     * @summary Find technique resources.
     * @request GET:/api/v1/scheduled-work-orders/technique-resources
     * @secure
     */
    v1ScheduledWorkOrdersTechniqueResourcesList: (params: RequestParams = {}) =>
      this.request<TechniqueResourcesResponse[], IdcProblemDetail>({
        path: `/api/v1/scheduled-work-orders/technique-resources`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceUnits
     * @name V1ServiceUnitsCreate
     * @summary Create service unit
     * @request POST:/api/v1/service-units
     * @secure
     */
    v1ServiceUnitsCreate: (data: CreateServiceUnitRequest, params: RequestParams = {}) =>
      this.request<ServiceUnitResponse, IdcProblemDetail>({
        path: `/api/v1/service-units`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceUnits
     * @name V1ServiceUnitsList
     * @summary Search service unit by name
     * @request GET:/api/v1/service-units
     * @secure
     */
    v1ServiceUnitsList: (
      query?: {
        /** @format int64 */
        ServiceProductId?: number;
        /** @format int64 */
        NodeId?: number;
        TagsIds?: number[];
        /** @format int64 */
        EquipmentGroupId?: number;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<SlimServiceUnitResponsePaginationResponse, IdcProblemDetail>({
        path: `/api/v1/service-units`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceUnits
     * @name V1ServiceUnitsUpdate
     * @summary Update service unit
     * @request PUT:/api/v1/service-units/{serviceUnitId}
     * @secure
     */
    v1ServiceUnitsUpdate: (serviceUnitId: number, data: UpdateServiceUnitRequest, params: RequestParams = {}) =>
      this.request<ServiceUnitResponse, IdcProblemDetail>({
        path: `/api/v1/service-units/${serviceUnitId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceUnits
     * @name V1ServiceUnitsDetail
     * @summary Find service unit by identifier
     * @request GET:/api/v1/service-units/{serviceUnitId}
     * @secure
     */
    v1ServiceUnitsDetail: (serviceUnitId: number, params: RequestParams = {}) =>
      this.request<ServiceUnitResponse, IdcProblemDetail>({
        path: `/api/v1/service-units/${serviceUnitId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceUnits
     * @name V1ServiceUnitsCommonInfoList
     * @summary Common info
     * @request GET:/api/v1/service-units/common-info
     * @secure
     */
    v1ServiceUnitsCommonInfoList: (params: RequestParams = {}) =>
      this.request<CommonInfoResponse, any>({
        path: `/api/v1/service-units/common-info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceUnits
     * @name V1ServiceUnitsServiceProductsList
     * @summary Find service products by name
     * @request GET:/api/v1/service-units/service-products
     * @secure
     */
    v1ServiceUnitsServiceProductsList: (
      query?: {
        /** Service product name */
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ServiceProductResponse[], any>({
        path: `/api/v1/service-units/service-products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceUnits
     * @name V1ServiceUnitsEquipmentGroupsList
     * @summary Find equipment groups by name
     * @request GET:/api/v1/service-units/equipment-groups
     * @secure
     */
    v1ServiceUnitsEquipmentGroupsList: (
      query?: {
        /** Service product name */
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ServiceProductResponse[], any>({
        path: `/api/v1/service-units/equipment-groups`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceUnits
     * @name V1ServiceUnitsManufacturersList
     * @summary Find manufacturer
     * @request GET:/api/v1/service-units/manufacturers
     * @secure
     */
    v1ServiceUnitsManufacturersList: (
      query?: {
        /** Manufacturer name */
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ServiceProductResponse[], any>({
        path: `/api/v1/service-units/manufacturers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceUnits
     * @name V1ServiceUnitsFilesCreate
     * @summary Upload vendor file
     * @request POST:/api/v1/service-units/{serviceUnitId}/files
     * @secure
     */
    v1ServiceUnitsFilesCreate: (serviceUnitId: number, params: RequestParams = {}) =>
      this.request<FileResponse[], IdcProblemDetail>({
        path: `/api/v1/service-units/${serviceUnitId}/files`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceUnits
     * @name V1ServiceUnitsFilesDelete
     * @summary Delete service unit file
     * @request DELETE:/api/v1/service-units/{serviceUnitId}/files/{fileId}
     * @secure
     */
    v1ServiceUnitsFilesDelete: (serviceUnitId: number, fileId: number, params: RequestParams = {}) =>
      this.request<void, IdcProblemDetail>({
        path: `/api/v1/service-units/${serviceUnitId}/files/${fileId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceUnits
     * @name V1ServiceUnitsServiceProductCreate
     * @summary Create service product
     * @request POST:/api/v1/service-units/service-product
     * @secure
     */
    v1ServiceUnitsServiceProductCreate: (data: CreateServiceProductRequest, params: RequestParams = {}) =>
      this.request<ServiceProductResponse, IdcProblemDetail>({
        path: `/api/v1/service-units/service-product`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name V1StatisticsCreate
     * @summary Create statistic info.
     * @request POST:/api/v1/statistics
     * @secure
     */
    v1StatisticsCreate: (data: CreateStatisticInfoRequest, params: RequestParams = {}) =>
      this.request<StatisticInfoResponse, any>({
        path: `/api/v1/statistics`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name V1StatisticsList
     * @summary Get statistic info.
     * @request GET:/api/v1/statistics
     * @secure
     */
    v1StatisticsList: (params: RequestParams = {}) =>
      this.request<StatisticInfoResponse[], IdcProblemDetail>({
        path: `/api/v1/statistics`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name V1StatisticsUpdate
     * @summary Update statistic info.
     * @request PUT:/api/v1/statistics/{statisticInfoId}
     * @secure
     */
    v1StatisticsUpdate: (statisticInfoId: number, data: UpdateStatisticInfoRequest, params: RequestParams = {}) =>
      this.request<StatisticInfoResponse, IdcProblemDetail>({
        path: `/api/v1/statistics/${statisticInfoId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name V1StatisticsScheduledWorkOrdersList
     * @summary Scheduled work orders statistic info.
     * @request GET:/api/v1/statistics/scheduled-work-orders
     * @secure
     */
    v1StatisticsScheduledWorkOrdersList: (
      query: {
        /**
         * @format date
         * @example "2023-12-31"
         */
        StartDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        EndDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        StartContractDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        EndContractDate: string;
        /** @format int64 */
        CompanyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ScheduledWorkOrderStatisticInfoResponse, IdcProblemDetail>({
        path: `/api/v1/statistics/scheduled-work-orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name V1StatisticsEmergencyTasksList
     * @summary Emergency tasks statistic info.
     * @request GET:/api/v1/statistics/emergency-tasks
     * @secure
     */
    v1StatisticsEmergencyTasksList: (
      query: {
        /**
         * @format date
         * @example "2023-12-31"
         */
        StartDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        EndDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        StartContractDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        EndContractDate: string;
        /** @format int64 */
        CompanyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmergencyTaskStatisticResponse, IdcProblemDetail>({
        path: `/api/v1/statistics/emergency-tasks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name V1StatisticsUnscheduledTasksList
     * @summary Unscheduled tasks statistic info.
     * @request GET:/api/v1/statistics/unscheduled-tasks
     * @secure
     */
    v1StatisticsUnscheduledTasksList: (
      query: {
        /**
         * @format date
         * @example "2023-12-31"
         */
        StartDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        EndDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        StartContractDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        EndContractDate: string;
        /** @format int64 */
        CompanyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UnscheduledTaskStatisticResponse, IdcProblemDetail>({
        path: `/api/v1/statistics/unscheduled-tasks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name V1StatisticsContractorsList
     * @summary Contractors info.
     * @request GET:/api/v1/statistics/contractors
     * @secure
     */
    v1StatisticsContractorsList: (
      query: {
        /**
         * @format date
         * @example "2023-12-31"
         */
        StartDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        EndDate: string;
        /** @format int64 */
        CompanyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractorsStatisticResponse, IdcProblemDetail>({
        path: `/api/v1/statistics/contractors`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name V1StatisticsLoadByTasksList
     * @summary Load by tasks info.
     * @request GET:/api/v1/statistics/load-by-tasks
     * @secure
     */
    v1StatisticsLoadByTasksList: (
      query: {
        CompanyIds?: number[];
        /**
         * @format date
         * @example "2023-12-31"
         */
        StartDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        EndDate: string;
        /** @format int64 */
        CompanyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<LoadByTasksStatisticResponse, IdcProblemDetail>({
        path: `/api/v1/statistics/load-by-tasks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name V1StatisticsCompaniesList
     * @summary Get companies that have tasks.
     * @request GET:/api/v1/statistics/companies
     * @secure
     */
    v1StatisticsCompaniesList: (
      query: {
        /**
         * @format date
         * @example "2023-12-31"
         */
        StartDate: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        EndDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CompanyThatHaveTasksResponse, any>({
        path: `/api/v1/statistics/companies`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name V1StatisticsFilesLogList
     * @summary Get files log.
     * @request GET:/api/v1/statistics/files-log
     * @secure
     */
    v1StatisticsFilesLogList: (
      query?: {
        /**
         * @format date
         * @example "2023-12-31"
         */
        StartDate?: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        EndDate?: string;
        FileTypes?: FileTypeWebModel[];
        UserIds?: number[];
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<FileLogResponsePaginationResponse, any>({
        path: `/api/v1/statistics/files-log`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name V1StatisticsFilesFilesLogList
     * @summary Download files log.
     * @request GET:/api/v1/statistics/files/files-log
     * @secure
     */
    v1StatisticsFilesFilesLogList: (
      query?: {
        /**
         * @format date
         * @example "2023-12-31"
         */
        StartDate?: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        EndDate?: string;
        FileTypes?: FileTypeWebModel[];
        UserIds?: number[];
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/v1/statistics/files/files-log`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subjects
     * @name V1SubjectsList
     * @summary Get all subjects
     * @request GET:/api/v1/subjects
     * @secure
     */
    v1SubjectsList: (params: RequestParams = {}) =>
      this.request<SubjectResponse, IdcProblemDetail>({
        path: `/api/v1/subjects`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name V1TagsCreate
     * @summary Create tag
     * @request POST:/api/v1/tags
     * @secure
     */
    v1TagsCreate: (data: TagCreateRequest, params: RequestParams = {}) =>
      this.request<TagResponse, IdcProblemDetail>({
        path: `/api/v1/tags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name V1TagsList
     * @summary Find tags
     * @request GET:/api/v1/tags
     * @secure
     */
    v1TagsList: (
      query?: {
        Name?: string;
        /** @format int32 */
        Take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<TagResponse[], IdcProblemDetail>({
        path: `/api/v1/tags`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translation
     * @name V1TranslationDetail
     * @summary Get translation task information.
     * @request GET:/api/v1/translation/{translationTaskId}
     * @secure
     */
    v1TranslationDetail: (
      translationTaskId: number,
      query?: {
        /** Translation task type */
        type?: TranslationTaskType;
      },
      params: RequestParams = {},
    ) =>
      this.request<TranslationTaskResponse, IdcProblemDetail>({
        path: `/api/v1/translation/${translationTaskId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translation
     * @name V1TranslationUpdate
     * @summary Update translation task.
     * @request PUT:/api/v1/translation/{translationTaskId}
     * @secure
     */
    v1TranslationUpdate: (translationTaskId: number, data: UpdateTranslationTaskRequest, params: RequestParams = {}) =>
      this.request<TranslationTaskResponse, IdcProblemDetail>({
        path: `/api/v1/translation/${translationTaskId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translation
     * @name V1TranslationList
     * @summary Get all available translation tasks for current user.
     * @request GET:/api/v1/translation
     * @secure
     */
    v1TranslationList: (params: RequestParams = {}) =>
      this.request<BoardTranslationTaskResponse[], any>({
        path: `/api/v1/translation`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UnscheduledTasks
     * @name V1UnscheduledTasksList
     * @summary Get all unscheduled tasks for the current user.
     * @request GET:/api/v1/unscheduled-tasks
     * @secure
     */
    v1UnscheduledTasksList: (
      query?: {
        SubjectsIds?: number[];
        /**
         * @format date
         * @example "2023-12-31"
         */
        From?: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        To?: string;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<SlimUnscheduledTaskResponsePaginationResponse, IdcProblemDetail>({
        path: `/api/v1/unscheduled-tasks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UnscheduledTasks
     * @name V1UnscheduledTasksDetail
     * @summary Get unscheduled task.
     * @request GET:/api/v1/unscheduled-tasks/{unscheduledTaskId}
     * @secure
     */
    v1UnscheduledTasksDetail: (unscheduledTaskId: number, params: RequestParams = {}) =>
      this.request<UnscheduledTaskResponse, IdcProblemDetail>({
        path: `/api/v1/unscheduled-tasks/${unscheduledTaskId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UnscheduledTasks
     * @name V1UnscheduledTasksUpdate
     * @summary Update unscheduled task.
     * @request PUT:/api/v1/unscheduled-tasks/{unscheduledTaskId}
     * @secure
     */
    v1UnscheduledTasksUpdate: (
      unscheduledTaskId: number,
      data: UnscheduledTaskUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<UnscheduledTaskResponse, IdcProblemDetail>({
        path: `/api/v1/unscheduled-tasks/${unscheduledTaskId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UnscheduledTasks
     * @name V1UnscheduledTasksRegistryList
     * @summary Get unscheduled tasks registry.
     * @request GET:/api/v1/unscheduled-tasks/registry
     * @secure
     */
    v1UnscheduledTasksRegistryList: (
      query?: {
        SubjectsIds?: number[];
        /**
         * @format date
         * @example "2023-12-31"
         */
        From?: string;
        /**
         * @format date
         * @example "2023-12-31"
         */
        To?: string;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, IdcProblemDetail>({
        path: `/api/v1/unscheduled-tasks/registry`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersCurrentList
     * @summary Get current user information
     * @request GET:/api/v1/users/current
     * @secure
     */
    v1UsersCurrentList: (params: RequestParams = {}) =>
      this.request<UserResponse, any>({
        path: `/api/v1/users/current`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersDetail
     * @summary Get user information
     * @request GET:/api/v1/users/{id}
     * @secure
     */
    v1UsersDetail: (id: number, params: RequestParams = {}) =>
      this.request<UserResponse, IdcProblemDetail>({
        path: `/api/v1/users/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersUpdate
     * @summary Update user
     * @request PUT:/api/v1/users/{id}
     * @secure
     */
    v1UsersUpdate: (id: number, data: UpdateUserRequest, params: RequestParams = {}) =>
      this.request<UserResponse, IdcProblemDetail>({
        path: `/api/v1/users/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersCreate
     * @summary Create user
     * @request POST:/api/v1/users
     * @secure
     */
    v1UsersCreate: (data: CreateUserRequest, params: RequestParams = {}) =>
      this.request<UserResponse, IdcProblemDetail>({
        path: `/api/v1/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersList
     * @summary Get all users
     * @request GET:/api/v1/users
     * @secure
     */
    v1UsersList: (params: RequestParams = {}) =>
      this.request<UserResponse[], any>({
        path: `/api/v1/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersConfirmEmailAddressList
     * @summary Send email code to email
     * @request GET:/api/v1/users/confirm-email-address
     * @secure
     */
    v1UsersConfirmEmailAddressList: (params: RequestParams = {}) =>
      this.request<void, IdcProblemDetail>({
        path: `/api/v1/users/confirm-email-address`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersConfirmEmailAddressCreate
     * @summary Confirm email code
     * @request POST:/api/v1/users/confirm-email-address
     * @secure
     */
    v1UsersConfirmEmailAddressCreate: (
      query?: {
        /**
         * Confirm code
         * @format int32
         */
        code?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserResponse, IdcProblemDetail>({
        path: `/api/v1/users/confirm-email-address`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersPasswordUpdate
     * @summary Change user password
     * @request PUT:/api/v1/users/password
     * @secure
     */
    v1UsersPasswordUpdate: (data: ChangeUserPasswordRequest, params: RequestParams = {}) =>
      this.request<UserResponse, IdcProblemDetail>({
        path: `/api/v1/users/password`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersNotificationsList
     * @summary Get current user notification
     * @request GET:/api/v1/users/notifications
     * @secure
     */
    v1UsersNotificationsList: (
      query?: {
        Type?: NotificationType;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        ColumnName?: string;
        Ordering?: Ordering;
      },
      params: RequestParams = {},
    ) =>
      this.request<NotificationResponsePaginationResponse, IdcProblemDetail>({
        path: `/api/v1/users/notifications`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersNotificationsUpdate
     * @summary Update current user notification.
     * @request PUT:/api/v1/users/notifications/{notificationId}
     * @secure
     */
    v1UsersNotificationsUpdate: (notificationId: number, data: UpdateNotificationRequest, params: RequestParams = {}) =>
      this.request<NotificationResponse, IdcProblemDetail>({
        path: `/api/v1/users/notifications/${notificationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersNotificationsViewedUpdate
     * @summary Set all notifications as viewed.
     * @request PUT:/api/v1/users/notifications/viewed
     * @secure
     */
    v1UsersNotificationsViewedUpdate: (params: RequestParams = {}) =>
      this.request<number, IdcProblemDetail>({
        path: `/api/v1/users/notifications/viewed`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersRolesList
     * @summary Get all roles
     * @request GET:/api/v1/users/roles
     * @secure
     */
    v1UsersRolesList: (params: RequestParams = {}) =>
      this.request<UserRoleTypeEnumResponse[], any>({
        path: `/api/v1/users/roles`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersDisciplinesList
     * @summary Get all disciplines
     * @request GET:/api/v1/users/disciplines
     * @secure
     */
    v1UsersDisciplinesList: (params: RequestParams = {}) =>
      this.request<DisciplineResponse[], any>({
        path: `/api/v1/users/disciplines`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
