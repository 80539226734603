import { createApi } from '@reduxjs/toolkit/query/react';
import { File, FileExtensionResponse, FileResponse, PlanFileResponse, ProfilePhotoResponse } from 'types';
import type { GetParams } from 'types/global';

import { baseQuery } from './baseQuery';

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: baseQuery,
  tagTypes: ['SupportedExtensions', 'Files', 'User', 'Plans'],
  endpoints: builder => ({
    getSupportedExtensions: builder.query<FileExtensionResponse[], void>({
      query: () => ({
        url: 'Files/supported-extensions',
      }),
      providesTags: () => ['SupportedExtensions'],
    }),
    // # Files
    uploadFiles: builder.mutation<FileResponse, { id: number; form: FormData }>({
      query: ({ id, form }) => ({
        url: `/files/nodes/${id}`,
        method: 'POST',
        body: form,
      }),
      invalidatesTags: ['Files'],
    }),
    uploadFilesSmart: builder.mutation<FileResponse[], FormData>({
      query: form => ({
        url: '/files/smart',
        method: 'POST',
        body: form,
      }),
      invalidatesTags: ['Files'],
    }),
    // # Files
    // uploadEmergencyTaskFiles: builder.mutation<FileResponse, FormData>({
    //   query: form => ({
    //     url: '/files/emergencyTask',
    //     method: 'POST',
    //     body: form,
    //   }),
    //   invalidatesTags: ['Files'],
    // }),
    uploadProfilePhoto: builder.mutation<ProfilePhotoResponse, FormData>({
      query: form => ({
        url: '/files/profile-photos',
        method: 'POST',
        body: form,
      }),
      invalidatesTags: ['User'],
    }),
    // downloadFile: builder.query<Blob, FileResponse | SlimServiceUnitFileResponse | ScheduledWorkOrderFileResponse | AppFile | SlimFileResponse>({
    //   query: ({id, type, extension}) => {
    //     const route = IMAGE.includes(extension) ? 'image/' : PDF.includes(extension) ? 'pdf/' : ''
    //     return ({
    //       url: '/files/' + route + id + '?fileType=' + type,
    //       responseType: 'blob',
    //       responseHandler: async (response) => await getBlobFile(response, true),
    //       cache: 'no-cache',
    //     })
    //   },
    //   providesTags: result => []
    // }),
    getPlans: builder.query<PlanFileResponse[], GetParams>({
      query: params => ({
        url: 'files/plans',
        params,
      }),
      providesTags: () => ['Plans'],
    }),
    getArchivePlans: builder.query<File, GetParams>({
      query: params => ({
        url: 'files/plans/archive',
        params,
      }),
      providesTags: () => ['Plans'],
    }),
    deletePlanById: builder.mutation<unknown, number>({
      query: planFileId => ({
        url: `files/plans/${planFileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Plans'],
    }),
  }),
});
