import { createApi } from '@reduxjs/toolkit/query/react';
import { TagResponse } from 'types';

import { baseQuery } from './baseQuery';

export const tagApi = createApi({
  reducerPath: 'tagApi',
  baseQuery: baseQuery,
  tagTypes: ['Tags'],
  endpoints: builder => ({
    getTags: builder.query<TagResponse[], string>({
      query: name => ({
        url: 'tags',
        params: {
          name,
        },
      }),
      providesTags: () => ['Tags'],
    }),
  }),
});
