import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateRegulatoryDocumentRequest,
  Ordering,
  RegulatoryDocumentResponse,
  RegulatoryDocumentResponsePaginationResponse,
} from 'types';

import { baseQuery } from './baseQuery';

export interface RegulatoryDocumentsListRequestParams {
  /** @format int64 */
  companyId?: number;
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  columnName?: string;
  ordering?: Ordering;
}

export interface RegulatoryDocumentsSearchRequestParams {
  code?: string;
  name?: string;
  /** @format int32 */
  take?: number;
}

export const regulatoryDocumentsApi = createApi({
  reducerPath: 'regulatoryDocumentsApi',
  baseQuery: baseQuery,
  tagTypes: ['RegulatoryDocuments'],
  endpoints: builder => ({
    getAll: builder.query<
      RegulatoryDocumentResponsePaginationResponse,
      RegulatoryDocumentsListRequestParams | undefined
    >({
      query: params => {
        return {
          url: 'regulatory-documents/library',
          params: params,
        };
      },
      providesTags: () => ['RegulatoryDocuments'],
    }),
    find: builder.query<RegulatoryDocumentResponse[], RegulatoryDocumentsSearchRequestParams>({
      query: params => ({
        url: 'regulatory-documents',
        params: params,
      }),
      providesTags: () => ['RegulatoryDocuments'],
    }),
    create: builder.mutation<RegulatoryDocumentResponse, CreateRegulatoryDocumentRequest>({
      query: body => ({
        url: 'regulatory-documents',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['RegulatoryDocuments'],
    }),
  }),
});
