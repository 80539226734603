import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';

interface AddButtonProps {
  title: string;
  onClick: () => void;
  size?: SizeType;
}

export default function AddButton({ title, onClick, size = 'small' }: AddButtonProps) {
  return (
    <Tooltip title={title}>
      <Button size={size} icon={<PlusOutlined className="!text-xs" />} onClick={onClick} />
    </Tooltip>
  );
}
