import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { Modal, Table } from 'antd/lib';
import { companyAPI } from 'services/CompanyService';
import { regulatoryDocumentsApi } from 'services/RegulatoryDocumentsService';
import { userApi } from 'services/UserService';
import { RegulatoryDocumentResponse, UserRoleType } from 'types';

import AddButton from 'components/AddButton';
import Spinner from 'components/Spinner';

import AddRegulatoryDocument from './components/AddRegulatoryDocument';

export default function RegulatoryDocumentReference() {
  const { t } = useTranslation('main-panel');
  const { isLoading, data: regulatoryDocumentReference } = regulatoryDocumentsApi.useGetAllQuery({ pageSize: 10000 });
  const { data: user, isLoading: currentUserLoading } = userApi.useGetCurrentUserQuery();
  const [addToolModalOpen, setAddToolModalOpen] = useState(false);
  const { data: companies = [] } = companyAPI.useGetAllQuery();

  const columns: ColumnsType<RegulatoryDocumentResponse> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      className: 'text-primary [&&&]:p-0 h-16',
      sorter: (a, b) => (a.id ?? 0) - (b.id ?? 0),
      onHeaderCell: () => ({ className: '[&&&]:text-primary [&&&]:text-center' }),
      width: 180,
    },
    {
      title: t('Code'),
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      className: 'text-primary [&&&]:p-0 h-16',
      sorter: (a, b) => (a.code && b.code ? a.code.localeCompare(b.code) : 0),
      onHeaderCell: () => ({ className: '[&&&]:text-primary [&>.ant-table-column-sorters]:w-full' }),
      width: 180,
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      className: 'text-primary [&&&]:p-0 h-16',
      sorter: (a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0),
      onHeaderCell: () => ({ className: '[&&&]:text-primary [&>.ant-table-column-sorters]:w-full' }),
    },
    {
      title: t('Company'),
      dataIndex: 'companyId',
      key: 'companyId',
      className: 'text-primary [&&&]:p-0 h-16',
      onHeaderCell: () => ({ className: '[&&&]:text-primary [&&&]:text-center' }),
      render: (_id?: number) => companies.find(({ id }) => id === _id)?.name,
      sorter: (a, b) => (a.companyId ?? 0) - (b.companyId ?? 0),
      width: 180,
    },
  ];

  const dataSource = [...(regulatoryDocumentReference?.data ?? [])].sort((a, b) => (a.id ?? 0) - (b.id ?? 0));

  if (currentUserLoading || user?.role !== UserRoleType.Admin) {
    return null;
  }

  if (isLoading || !regulatoryDocumentReference) {
    return <Spinner />;
  }

  return (
    <div className="flex h-full max-h-full flex-col pb-5">
      <div className="mb-10 mt-3.5 flex items-center gap-2">
        <h3 className="typography-h3 font-bold text-primary">{t('Reference book on regulatory documentation')}</h3>
      </div>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        className="mb-2 max-h-[calc(100%-56px-48px-8px)] grow [&>div>div>div>div]:max-h-full [&>div>div>div>div]:overflow-auto [&>div>div>div]:flex [&>div>div>div]:max-h-full [&>div>div]:max-h-full [&>div>div]:max-w-full [&>div]:flex [&>div]:max-h-full"
        sticky
      />
      <AddButton title="Add regulatory document" size="large" onClick={() => setAddToolModalOpen(true)} />
      <Modal
        width={650}
        classNames={{ content: '[&&&]:rounded-[32px]' }}
        footer={null}
        open={addToolModalOpen}
        onCancel={() => setAddToolModalOpen(false)}
        destroyOnClose
      >
        <AddRegulatoryDocument onClose={() => setAddToolModalOpen(false)} />
      </Modal>
    </div>
  );
}
