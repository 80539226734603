import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BoardScheduledWorkOrderResponsePaginationResponse,
  Ordering,
  ScheduledTaskResponse,
  ScheduledWorkOrderFileResponse,
  ScheduledWorkOrderGlobalStatus,
  ScheduledWorkOrderResponse,
  ScheduledWorkOrderWebResponse,
  ScheduledWorkPeriodWebModel,
  ScheduledWorkResponse,
  ScheduleTaskUpdateRequest,
  ScheduleWorkOrderUpdateRequest,
  TechniqueResourcesResponse,
} from 'types';

import { baseQuery } from './baseQuery';

export interface SlimScheduledWorkOrdersRequest {
  companiesIds?: number[];
  subjectsIds?: number[];
  subjectWithChildIds?: number[];
  userIds?: number[];
  scheduledWorksIds?: number[];
  workPeriods?: ScheduledWorkPeriodWebModel[];
  globalStatuses?: ScheduledWorkOrderGlobalStatus[];
  isAwaitingUserAction?: boolean;
  pageNumber?: number;
  pageSize?: number;
  columnName?: string;
  ordering?: Ordering;
  from?: string;
  to?: string;
}

export const scheduledWorkOrderApi = createApi({
  reducerPath: 'scheduledWorkOrderApi',
  baseQuery: baseQuery,
  tagTypes: [
    'ScheduledWorkOrders',
    'ScheduledWorkOrder',
    'ScheduledTask',
    'ScheduledWorkOrdersRegistry',
    'ScheduledWorks',
    'ScheduledWorkOrdersTechniqueResources',
  ],
  endpoints: builder => ({
    getAll: builder.query<BoardScheduledWorkOrderResponsePaginationResponse, SlimScheduledWorkOrdersRequest>({
      query: params => {
        return {
          url: 'scheduled-work-orders',
          params: params,
        };
      },
      providesTags: () => ['ScheduledWorkOrders'],
    }),
    getById: builder.query<ScheduledWorkOrderResponse, number>({
      query: id => ({
        url: `scheduled-work-orders/${id}`,
      }),
      providesTags: () => ['ScheduledWorkOrder'],
    }),
    getScheduledTaskById: builder.query<ScheduledTaskResponse, { id: number; scheduledTaskId: number }>({
      query: ({ id, scheduledTaskId }) => ({
        url: `scheduled-work-orders/${id}/scheduled-tasks/${scheduledTaskId}`,
      }),
      providesTags: () => ['ScheduledTask'],
    }),
    updateScheduledWorkOrder: builder.mutation<
      ScheduledWorkOrderResponse,
      {
        id: number;
        body: ScheduleWorkOrderUpdateRequest;
      }
    >({
      query: ({ id, body }) => ({
        url: `scheduled-work-orders/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['ScheduledWorkOrder', 'ScheduledWorkOrders'],
    }),
    updateScheduledTask: builder.mutation<
      ScheduledTaskResponse,
      {
        scheduledWorkOrderId: number;
        scheduledTaskId: number;
        body: ScheduleTaskUpdateRequest;
      }
    >({
      query: ({ scheduledWorkOrderId, scheduledTaskId, body }) => ({
        url: `scheduled-work-orders/${scheduledWorkOrderId}/scheduled-tasks/${scheduledTaskId}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['ScheduledTask', 'ScheduledWorkOrder', 'ScheduledWorkOrders'],
    }),
    uploadScheduledWorkOrderFiles: builder.mutation<ScheduledWorkOrderFileResponse, { id: number; form: FormData }>({
      query: ({ id, form }) => ({
        url: `scheduled-work-orders/${id}/files`,
        method: 'POST',
        body: form,
      }),
      invalidatesTags: ['ScheduledWorkOrder'],
    }),
    deleteFile: builder.mutation<number, { id: number; fileId: number }>({
      query: ({ id, fileId }) => ({
        url: `scheduled-work-orders/${id}/files/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ScheduledWorkOrder'],
    }),
    getChecklist: builder.query<File, { id: number; scheduledTaskId: number }>({
      query: ({ id, scheduledTaskId }) => ({
        url: `scheduled-work-orders/${id}/scheduled-tasks/${scheduledTaskId}/checklist`,
      }),
      providesTags: () => ['ScheduledTask'],
    }),
    getChecklistPreview: builder.query<File, { scheduledWorkOrderId: number; scheduledTaskId: number; body: object }>({
      query: ({ scheduledWorkOrderId, scheduledTaskId, body }) => ({
        url: `scheduled-work-orders/${scheduledWorkOrderId}/scheduled-tasks/${scheduledTaskId}/checklist/preview`,
        method: 'POST',
        body,
      }),
      providesTags: () => ['ScheduledTask'],
    }),
    deleteScheduledTask: builder.mutation<unknown, { scheduledWorkOrderId: number; scheduledTaskId: number }>({
      query: ({ scheduledWorkOrderId, scheduledTaskId }) => ({
        url: `scheduled-work-orders/${scheduledWorkOrderId}/scheduled-tasks/${scheduledTaskId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [],
    }),
    deleteScheduledWorkOrder: builder.mutation<unknown, number>({
      query: scheduledWorkOrderId => ({
        url: `scheduled-work-orders/${scheduledWorkOrderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['ScheduledWorkOrders'],
    }),
    getArchive: builder.query<File, SlimScheduledWorkOrdersRequest>({
      query: params => {
        return {
          url: 'scheduled-work-orders/archive',
          params: params,
        };
      },
      providesTags: () => ['ScheduledWorkOrdersRegistry'],
    }),
    getTechnicalResourcesReport: builder.query<File, SlimScheduledWorkOrdersRequest>({
      query: params => {
        return {
          url: 'scheduled-work-orders/technical-resources-report',
          params: params,
        };
      },
      providesTags: () => ['ScheduledWorkOrdersRegistry'],
    }),
    getHumanResourcesReport: builder.query<File, SlimScheduledWorkOrdersRequest>({
      query: params => {
        return {
          url: 'scheduled-work-orders/human-resources-report',
          params: params,
        };
      },
      providesTags: () => ['ScheduledWorkOrdersRegistry'],
    }),
    getScheduledWorks: builder.query<ScheduledWorkResponse[], void>({
      query: () => ({
        url: 'scheduled-work-orders/scheduled-works',
      }),
      providesTags: () => ['ScheduledWorks'],
    }),
    getTechniqueResources: builder.query<TechniqueResourcesResponse[], void>({
      query: () => ({
        url: 'scheduled-work-orders/technique-resources',
      }),
      providesTags: () => ['ScheduledWorkOrdersTechniqueResources'],
    }),
    getWebReport: builder.query<ScheduledWorkOrderWebResponse, number>({
      query: scheduledWorkOrderId => ({
        url: `scheduled-work-orders/${scheduledWorkOrderId}/report/web`,
      }),

      providesTags: () => ['ScheduledTask'],
    }),
  }),
});
