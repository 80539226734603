import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Dropdown, MenuProps, Modal, Tooltip } from 'antd';
import { useAppDispatch } from 'redux/hooks';
import { logout } from 'redux/mainSlice';
import { routes } from 'router/routes';
import { companyAPI } from 'services/CompanyService';
import { emergencyTaskApi } from 'services/EmergencyTaskService';
import { positionApi } from 'services/PositionService';
import { scheduledWorkOrderApi } from 'services/ScheduledWorkOrdersService';
import { serviceUnitApi } from 'services/ServiceUnitService';
import { statisticsAPI } from 'services/StatisticsService';
import { subjectApi } from 'services/SubjectService';
import { tagApi } from 'services/TagService';
import { translationApi } from 'services/TranslationService';
import { unscheduledTaskApi } from 'services/UnscheduledTasksService';
import { userApi } from 'services/UserService';
import { UserResponse, UserRoleType } from 'types';
import { email } from 'utils/constants';

import RegulatoryDocumentReference from './components/RegulatoryDocumentReference/RegulatoryDocumentReference';
import ScheduledWorks from './components/ScheduledWorks/ScheduledWorks';
import ToolReference from './components/ToolReference/ToolReference';

interface NavbarDropDownProps {
  user: UserResponse;
}

export default function NavbarDropDown({ user }: NavbarDropDownProps) {
  const { t } = useTranslation('main-panel');
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const { name, surname, emailAddressConfirmed } = user;
  const logoutHandler = () => {
    dispatch(companyAPI.util.resetApiState());
    dispatch(emergencyTaskApi.util.resetApiState());
    // dispatch(enumApi.util.resetApiState());
    // dispatch(fileApi.util.resetApiState());
    // dispatch(nodeAPI.util.resetApiState());
    dispatch(positionApi.util.resetApiState());
    dispatch(scheduledWorkOrderApi.util.resetApiState());
    dispatch(serviceUnitApi.util.resetApiState());
    dispatch(statisticsAPI.util.resetApiState());
    dispatch(subjectApi.util.resetApiState());
    dispatch(tagApi.util.resetApiState());
    dispatch(unscheduledTaskApi.util.resetApiState());
    dispatch(userApi.util.resetApiState());
    dispatch(translationApi.util.resetApiState());

    dispatch(logout());
    history(routes.main);
  };

  const [modalContent, setModalContent] = useState<'planned' | 'tool' | 'regDoc' | null>(null);

  const { data: currentUser, isLoading: currentUserLoading } = userApi.useGetCurrentUserQuery();
  // условие скрытия словаря плановых работ
  const disableScheduledWorksVisibility = currentUser?.role === UserRoleType.Subcontractor || currentUserLoading;

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <Link className="whitespace-nowrap" to="#">
          {surname + ' ' + name}
        </Link>
      ),
      disabled: true,
    },
    disableScheduledWorksVisibility
      ? null
      : {
          type: 'divider',
        },
    disableScheduledWorksVisibility
      ? null
      : {
          key: '1',
          children: [
            {
              key: '1-1',
              label: t('Reference of planned tasks'),
              onClick: () => setModalContent('planned'),
            },
            {
              key: '1-2',
              label: t('Tool reference'),
              onClick: () => setModalContent('tool'),
            },
            {
              key: '1-3',
              label: t('Reference book on regulatory documentation'),
              style: { maxWidth: 196 },
              onClick: () => setModalContent('regDoc'),
            },
          ],
          label: t('Dictionary'),
        },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: <Link to={routes.profile}>{t('Profile')}</Link>,
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: <Link to={routes.helpdesk}>{t('Helpdesk')}</Link>,
      disabled: true,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: <a href={`mailto:${email}`}>{t('Technical Support')}</a>,
      disabled: false,
    },
    {
      type: 'divider',
    },
    {
      key: '5',
      label: (
        <Button type="text" icon={<LogoutOutlined />} onClick={logoutHandler}>
          {t('Logout')}
        </Button>
      ),
    },
  ];
  return (
    <Tooltip title="Show User Profile" placement="bottomRight">
      <Dropdown menu={{ items }} trigger={['click']}>
        <a onClick={e => e.preventDefault()} className="relative">
          {emailAddressConfirmed ? (
            <Avatar className="whitespace-nowrap bg-[#f56a00]">{surname[0] + name[0]}</Avatar>
          ) : (
            <Badge.Ribbon text="!" color="red">
              <Avatar className="whitespace-nowrap bg-[#f56a00]">{surname[0] + name[0]}</Avatar>
            </Badge.Ribbon>
          )}
        </a>
      </Dropdown>
      <Modal
        width="calc(100% - 6rem)"
        style={{
          top: '3.5rem',
          padding: 0,
        }}
        footer={null}
        destroyOnClose
        open={Boolean(modalContent)}
        onCancel={() => setModalContent(null)}
        rootClassName="[&>div]:!z-[1000]"
      >
        <div
          style={{
            height: 'calc(100vh - 7.25rem)',
          }}
          className={'overflow-hidden'}
        >
          {modalContent === 'planned' && <ScheduledWorks />}
          {modalContent === 'tool' && <ToolReference />}
          {modalContent === 'regDoc' && <RegulatoryDocumentReference />}
        </div>
      </Modal>
    </Tooltip>
  );
}
